import axios from 'axios';
import { Page } from '@marrlab-app-shared/types';

import config from '../../utils/config';
import {
  EndCallRequestParams,
  HumanBackup,
  StartCallRequestBody,
  StartCallRequestResponse,
  UploadCallsSpreadsheetPayload,
} from './types';
import { CampaignOperators } from '../common/types';

export const startCallRequest = async (body: StartCallRequestBody) => {
  if (body.human_backup) {
    return axios.post<StartCallRequestResponse>(`${config.API_BASE_URL}/api/create-call`, {
      agent_number: body.agent_number,
      user_number: body.user_number,
      prompt_params: body.prompt_params,
      human_backup: body.human_backup,
      human_backup_number: body.human_backup_number,
    });
  }

  return axios.post<StartCallRequestResponse>(`${config.API_BASE_URL}/api/v2/create-direct-call`, {
    user_number: body.user_number,
    orchestrator_number_assignment_id: body.orchestrator_number_assignment_id,
    prompt_params: body.prompt_params,
    enable_amd: body.enable_amd,
  });
};

export const handCallOverRequest = async (callId: string) => {
  await axios.post(
    `${config.API_BASE_URL}/api/outbound-calls/${callId}`,
    {
      action: 'transfer',
    },
  );
};

export const endCallRequest = async (body: EndCallRequestParams) => {
  if (body.human_backup) {
    await axios.post(
      `${config.API_BASE_URL}/api/outbound-calls/${body.twilio_conf_sid}`,
      {
        action: 'terminate',
      },
    );
  }

  await axios.post(`${config.API_BASE_URL}/api/v2/calls/${body.call_log_uuid}/end-direct-call`);
};

export const campaignOperatorsRequest = async () => (
  axios.get<CampaignOperators[]>(`${config.CALLS_HISTORY_API_BASE_URL}/api/orchestrator-number-assignment`)
);

export const humanBackupRequest = async () => (
  axios.get<Page<HumanBackup>>(`${config.CALLS_HISTORY_API_BASE_URL}/api/human-backups?skip=0&take=20`)
);

export const uploadCallsSpreadsheet = async ({
  file,
  orchestratorProviderId,
  callParams,
}: UploadCallsSpreadsheetPayload) => (
  axios.post<{ message: string }>(`${config.API_BASE_URL}/api/bulk-upload/`, {
    file,
    orchestratorProviderId,
    call_params: callParams.join(','),
  }, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
);
