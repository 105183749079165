import { useCallback, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import cn from 'classnames';
import {
  Form,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
} from 'antd';

import { OutboundCallState } from '../../types';
import { HumanBackup } from '../../../../api/outboundCall/types';

interface UserPhoneFieldProps {
  humanBackupList?: HumanBackup[];
  customerPhoneNumber: string;
  disabled: boolean;
  loading: boolean;
  onUpdate: (fieldName: keyof OutboundCallState, value: string | boolean) => void;
}

enum FieldStateOptionsEnum {
  predefined = 'predefined',
  plain = 'plain',
}

const fieldStateOptions = [
  { label: 'Predefined options', value: FieldStateOptionsEnum.predefined },
  { label: 'Phone number', value: FieldStateOptionsEnum.plain },
];

const UserPhoneField = ({
  humanBackupList,
  customerPhoneNumber,
  onUpdate,
  disabled,
  loading,
}: UserPhoneFieldProps) => {
  const [fieldState, setFieldState] = useState(FieldStateOptionsEnum.predefined);

  const onFieldStateChange = useCallback((event: RadioChangeEvent) => {
    onUpdate('customerPhoneNumber', '');
    setFieldState(event.target.value);
  }, [onUpdate]);

  const options = humanBackupList?.map((item) => ({
    label: item.name,
    value: item.phoneNumber,
  })) ?? [];

  return (
    <Form.Item label="User phone number" required>
      <Space direction="vertical" className="w-full">
        <Radio.Group
          options={fieldStateOptions}
          value={fieldState}
          onChange={onFieldStateChange}
          disabled={disabled}
        />
        {fieldState === FieldStateOptionsEnum.predefined && (
          <Select
            value={customerPhoneNumber}
            onChange={(value: string) => onUpdate('customerPhoneNumber', value)}
            options={options}
            loading={loading}
            disabled={disabled}
          />
        )}
        {fieldState === FieldStateOptionsEnum.plain && (
          <PhoneInput
            containerClass={cn('phone-input', { disabled })}
            value={customerPhoneNumber}
            onChange={(value: string) => onUpdate('customerPhoneNumber', value)}
            country="us"
            disabled={disabled}
          />
        )}
      </Space>
    </Form.Item>
  );
};

export default UserPhoneField;
