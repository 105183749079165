import { Flex } from 'antd';

import UploadFileForm from '../uploadFileForm';
import HowToUse from '../howToUse';
import { OutboundCallState } from '../../types';
import { CampaignOperators } from '../../../../api/common/types';

interface UploadFileFormProps {
  campaignOperatorsLoading: boolean;
  data: OutboundCallState;
  campaignsOptions: string[];
  agentOptions: { key: number; label: string; }[];
  campaignOperator?: CampaignOperators;
  onUpdate: (fieldName: keyof OutboundCallState, value: string | boolean | number) => void;
}

const CallsFromSpreadsheet = ({
  campaignOperatorsLoading,
  data,
  campaignsOptions,
  agentOptions,
  campaignOperator,
  onUpdate,
}: UploadFileFormProps) => (
  <Flex gap={48}>
    <UploadFileForm
      campaignOperatorsLoading={campaignOperatorsLoading}
      data={data}
      campaignsOptions={campaignsOptions}
      agentOptions={agentOptions}
      campaignOperator={campaignOperator}
      onUpdate={onUpdate}
    />
    <HowToUse campaignOperator={campaignOperator} />
  </Flex>
);

export default CallsFromSpreadsheet;
