/* eslint-disable react/jsx-props-no-spreading */
import { CSSProperties, useCallback } from 'react';
import { List, Switch, Typography } from 'antd';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { MenuOutlined } from '@ant-design/icons';
import cn from 'classnames';

import { ColumnSetting } from '../../types';
import { idColumnKey } from '../../../../constants/columnsInitialState';

import './index.scss';

interface ColumnSettingRowProps {
  item: ColumnSetting;
  onVisibilityUpdate: (key: string, checked: boolean) => void;
}

const ColumnSettingRow = ({ item, onVisibilityUpdate }: ColumnSettingRowProps) => {
  const isIdColumn = item.key === idColumnKey;

  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({
    id: item.key,
    disabled: isIdColumn,
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const onChange = useCallback((checked: boolean) => (
    onVisibilityUpdate(item.key, checked)
  ), [item.key, onVisibilityUpdate]);

  return (
    <List.Item
      ref={setNodeRef}
      className={cn({ 'column-setting-is-dragging': isDragging })}
      style={style}
      {...attributes}
    >
      <MenuOutlined
        className={cn('column-setting-drag-handle', { disabled: isIdColumn })}
        ref={setActivatorNodeRef}
        {...listeners}
      />
      <Typography.Text>{item.title}</Typography.Text>
      <Switch
        className="ml-auto"
        checked={item.visible}
        onChange={onChange}
      />
    </List.Item>
  );
};

export default ColumnSettingRow;
