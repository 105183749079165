import { useOrganization } from '@clerk/clerk-react';
import useCheckRole from '@marrlab-app-shared/hooks/auth';
import UserPermissions from '@marrlab-app-shared/constants/permissions';

interface OrganisationMetadata {
  customerId?: number;
}

export const useCustomerIdFromOrganization = () => {
  const { has } = useCheckRole();

  const { organization } = useOrganization();

  return {
    onlyCertainCustomer: !has(UserPermissions.manageInternalTools),
    customerId: (organization?.publicMetadata as OrganisationMetadata)?.customerId,
  };
};

export default useCustomerIdFromOrganization;
