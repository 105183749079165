import { useNavigate as useNavigateBase, useLocation } from 'react-router-dom';
import { useCallback } from 'react';

const useNavigate = () => {
  const navigate = useNavigateBase();
  const location = useLocation();

  const navigateToSignIn = useCallback(() => navigate('/sign-in'), [navigate]);
  const navigateToMain = useCallback(() => navigate('/'), [navigate]);
  const navigateBack = useCallback(() => navigate(-1), [navigate]);
  const navigateBackOrToCallHistory = useCallback(() => {
    if (location.key !== 'default') {
      navigate(-1);
    } else {
      navigateToMain();
    }
  }, [location, navigate, navigateToMain]);
  const navigateToCallDetails = useCallback((callId: number) => navigate(`call-details/${callId}`), [navigate]);
  const navigateToCallDetailsInNewTab = useCallback((callId: number) => (
    window.open(`/call-details/${callId}`, '_blank')
  ), []);
  const navigateToCallDetailsByUuidInNewTab = useCallback((callUuid: string) => (
    window.open(`/call-details/uuid/${callUuid}`, '_blank')
  ), []);

  return {
    navigateBack,
    navigateBackOrToCallHistory,
    navigateToMain,
    navigateToSignIn,
    navigateToCallDetails,
    navigateToCallDetailsInNewTab,
    navigateToCallDetailsByUuidInNewTab,
  };
};

export default useNavigate;
