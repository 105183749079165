import {
  Button,
  Flex,
  Form,
  Space,
  Spin,
  Tooltip,
} from 'antd';
import useDataFetch from '@marrlab-app-shared/hooks/data/useDataFetch';

import HumanBackupField from '../humanBackupField';
import AgentParams from '../agentParams';
import CallResponse from '../callResponse';
import UserPhoneField from '../userPhoneField';
import AmdSwitchField from '../amdSwitchField';
import CampaignOperatorFields from '../campaignOperatorFields';
import useOutboundCallLogic from '../../hooks/useOutboundCallLogic';
import { humanBackupRequest } from '../../../../api/outboundCall';
import { CampaignParams as CampaignParamsType, OutboundCallState } from '../../types';
import { CampaignOperators } from '../../../../api/common/types';

import './index.scss';

interface SingleCallProps {
  campaignOperatorsLoading: boolean;
  isStartCallDisabled: boolean;
  data: OutboundCallState;
  campaignsOptions: string[];
  agentOptions: { key: number; label: string; }[];
  campaignOperator?: CampaignOperators;
  onUpdate: (fieldName: keyof OutboundCallState, value: string | boolean | number) => void;
  onCampaignParamsUpdate: (propName: keyof CampaignParamsType, value: string) => void;
}

const SingleCall = ({
  campaignOperatorsLoading,
  isStartCallDisabled,
  data,
  campaignsOptions,
  agentOptions,
  campaignOperator,
  onUpdate,
  onCampaignParamsUpdate,
}: SingleCallProps) => {
  const {
    callResponse,
    callInProgress,
    callHandedOver,
    loading,
    startCall,
    callTakeover,
    clearFormState,
    endCall,
  } = useOutboundCallLogic({ state: data, campaignOperator });

  const {
    data: humanBackupList,
    loading: humanBackupListLoading,
  } = useDataFetch({ request: humanBackupRequest });

  return (
    <Flex align="flex-end" gap={48}>
      <Form layout="vertical" className="w-sm">
        <CampaignOperatorFields
          disabled={campaignOperatorsLoading || loading || callInProgress}
          data={data}
          campaignsOptions={campaignsOptions}
          agentOptions={agentOptions}
          onUpdate={onUpdate}
        />
        {campaignOperator && (
          <AgentParams
            data={data.campaignParams}
            agentParams={campaignOperator.agentParams}
            onUpdate={onCampaignParamsUpdate}
            disabled={campaignOperatorsLoading || loading || callInProgress}
          />
        )}
        <HumanBackupField
          humanBackupList={humanBackupList?.data}
          loading={humanBackupListLoading}
          humanBackupPhone={data.humanBackupPhone}
          disabled={campaignOperatorsLoading || loading || callInProgress}
          onUpdate={onUpdate}
        />
        <UserPhoneField
          humanBackupList={humanBackupList?.data}
          loading={humanBackupListLoading}
          customerPhoneNumber={data.customerPhoneNumber}
          disabled={campaignOperatorsLoading || loading || callInProgress}
          onUpdate={onUpdate}
        />
        <AmdSwitchField
          enableAmd={data.enableAmd}
          disabled={campaignOperatorsLoading || loading || callInProgress}
          onUpdate={onUpdate}
        />
        <Form.Item className="mb-0">
          <Space>
            {callInProgress ? (
              <Button
                className="call-button"
                type="primary"
                danger
                onClick={endCall}
              >
                End a call
              </Button>
            ) : (
              <Button
                className="call-button"
                type="primary"
                disabled={isStartCallDisabled || loading}
                onClick={startCall}
              >
                Start a call
              </Button>
            )}
            <Button
              type="primary"
              disabled={!callInProgress || loading || callHandedOver || !data.humanBackup}
              onClick={callTakeover}
            >
              Human takeover
            </Button>
            <Tooltip placement="top" title="Just resets the form">
              <Button
                type="primary"
                ghost
                disabled={!callInProgress || loading}
                onClick={clearFormState}
              >
                Start a new call
              </Button>
            </Tooltip>
            {(campaignOperatorsLoading || loading) && <Spin />}
          </Space>
        </Form.Item>
      </Form>
      <CallResponse
        state={data}
        callResponse={callResponse}
        campaignOperator={campaignOperator}
      />
    </Flex>
  );
};

export default SingleCall;
