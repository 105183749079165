import { Typography } from 'antd';
import AudioUrlCell from '@marrlab-app-shared/components/audioUrlCell';
import { formatDate } from '@marrlab-app-shared/utils/date/date';

import CampaignParamsCell from '../components/campaignParamsCell';
import TranscriptCell from '../components/transcriptCell';
import { CallStatusEnum, CallStatusNames } from '../../../constants';
import { formatDuration } from '../../../utils/date/time';
import { OrchestratorProvider, User } from '../../../api/common/types';
import { averageRatingRenderer } from '../../../utils/annotations';
import { getPreSignedAudioUrl } from '../../../api/callsHistory';
import { TableColumn } from '../types';

export const idColumnKey = 'callLogId';

export const emptyFilterValue = '-1';

export const callUuidClassName = 'call-history-call-uuid-column';

export const columnsInitialState: TableColumn[] = [
  {
    title: 'ID',
    dataIndex: idColumnKey,
    key: idColumnKey,
    fixed: 'left',
    sorter: true,
    width: 94,
    render: (text: number) => <Typography.Link>{text}</Typography.Link>,
    availableForExternal: true,
  },
  {
    className: callUuidClassName,
    title: 'Call uuid',
    dataIndex: 'callLogUuid',
    key: 'callLogUuid',
    sorter: true,
    width: 330,
    render: (text: number) => <Typography.Link>{text}</Typography.Link>,
    availableForExternal: true,
  },
  {
    title: 'Call status',
    dataIndex: 'callStatus',
    key: 'callStatus',
    sorter: true,
    width: 186,
    render: (status: CallStatusEnum) => CallStatusNames[status] ?? status,
    availableForExternal: true,
  },
  {
    title: 'Direction',
    dataIndex: 'callDirection',
    key: 'callDirection',
    render: (text: number) => (text === 0 ? 'Inbound' : 'Outbound'),
    sorter: true,
    width: 110,
    availableForExternal: true,
  },
  {
    title: 'Started',
    dataIndex: 'startDate',
    key: 'startDate',
    sorter: true,
    width: 200,
    render: (text: string) => formatDate(text, true),
    availableForExternal: true,
  },
  {
    title: 'Ended',
    dataIndex: 'endDate',
    key: 'endDate',
    sorter: true,
    width: 200,
    render: (text: string) => formatDate(text, true),
    availableForExternal: true,
  },
  {
    align: 'right',
    title: 'Duration',
    dataIndex: 'callDurationSec',
    key: 'callDurationSec',
    sorter: true,
    width: 140,
    render: (text?: number) => formatDuration(text),
    availableForExternal: true,
  },
  {
    title: 'Operator number',
    dataIndex: 'operatorNumber',
    key: 'operatorNumber',
    sorter: true,
    width: 120,
    availableForExternal: false,
  },
  {
    title: 'Agent number',
    dataIndex: 'agentNumber',
    key: 'agentNumber',
    sorter: true,
    width: 120,
    availableForExternal: true,
  },
  {
    title: 'Human backup',
    dataIndex: 'humanBackupNumber',
    key: 'humanBackupNumber',
    sorter: true,
    width: 120,
    availableForExternal: false,
  },
  {
    title: 'User number',
    dataIndex: 'userNumber',
    key: 'userNumber',
    sorter: true,
    width: 120,
    availableForExternal: true,
  },
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    sorter: true,
    width: 100,
    render: (user: User) => user?.number,
    availableForExternal: false,
  },
  {
    title: 'Customer',
    dataIndex: 'customer',
    key: 'customer',
    sorter: true,
    width: 110,
    render: (_, record) => record.orchestratorProvider?.campaign?.customer?.name,
    availableForExternal: true,
  },
  {
    title: 'Campaign',
    dataIndex: 'campaign',
    key: 'campaign',
    sorter: true,
    width: 120,
    render: (_, record) => record.orchestratorProvider?.campaign?.displayName,
    availableForExternal: true,
  },
  {
    title: 'Campaign params',
    dataIndex: 'campaignJson',
    key: 'campaignJson',
    render: (_, record) => (
      <CampaignParamsCell
        orchestratorProvider={record.orchestratorProvider}
        promptParameters={record.promptParameters}
        agentProvider={record.agentProvider}
      />
    ),
    sorter: false,
    width: 210,
    availableForExternal: false,
  },
  {
    title: 'Customer call ID',
    dataIndex: 'customerCallId',
    key: 'customerCallId',
    sorter: true,
    width: 330,
    render: (_, record) => (record.customerCreateCallApiRequest?.customerCallId),
    availableForExternal: true,
  },
  {
    title: 'Twilio conf SID',
    dataIndex: 'twilioConfSid',
    key: 'twilioConfSid',
    sorter: true,
    width: 330,
    availableForExternal: false,
  },
  {
    title: 'Twilio agent call SID',
    dataIndex: 'twilioAgentCallSid',
    key: 'twilioAgentCallSid',
    sorter: true,
    width: 330,
    availableForExternal: false,
  },
  {
    title: 'Twilio human backup call SID',
    dataIndex: 'twilioHumanBackupCallSid',
    key: 'twilioHumanBackupCallSid',
    sorter: true,
    width: 330,
    availableForExternal: false,
  },
  {
    title: 'Twilio contact call SID',
    dataIndex: 'twilioContactCallSid',
    key: 'twilioContactCallSid',
    sorter: true,
    width: 330,
    availableForExternal: false,
  },
  {
    title: 'Retell call ID',
    dataIndex: 'retellCallId',
    key: 'retellCallId',
    sorter: true,
    width: 330,
    availableForExternal: false,
  },
  {
    title: 'Orchestrator provider ID',
    dataIndex: 'orchestratorProvider',
    key: 'orchestratorProvider',
    sorter: true,
    width: 330,
    render: (orchestratorProvider?: OrchestratorProvider) => (
      orchestratorProvider?.orchestratorProviderId
    ),
    availableForExternal: false,
  },
  {
    title: 'Audio URL',
    dataIndex: 'audioUrl',
    key: 'audioUrl',
    render: (value: string, record) => (
      <AudioUrlCell
        value={value}
        callId={record.callLogId}
        getPreSignedAudioUrl={getPreSignedAudioUrl}
      />
    ),
    sorter: true,
    width: 160,
    availableForExternal: true,
  },
  {
    title: 'Transcript',
    dataIndex: 'callTranscript',
    key: 'callTranscript',
    render: (_, record) => <TranscriptCell value={record.callTranscriptJson} />,
    sorter: true,
    width: 360,
    availableForExternal: true,
  },
  {
    title: 'Average human like rating',
    dataIndex: 'humanLikeRating',
    key: 'humanLikeRating',
    render: (_, record) => averageRatingRenderer(record.annotations),
    width: 140,
    availableForExternal: false,
  },
];
