import { Protect } from '@clerk/clerk-react';
import { UserPermissions } from '@marrlab-app-shared/constants/permissions';
import { useCheckRole } from '@marrlab-app-shared/hooks/auth';

import OutboundCallForm from '../../features/outboundCallForm';

const OutboundCall = () => {
  const { has } = useCheckRole();

  return (
    <Protect condition={() => has(UserPermissions.manageInternalTools)}>
      <OutboundCallForm />
    </Protect>
  );
};

export default OutboundCall;
