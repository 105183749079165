import { useEffect, useState } from 'react';
import { useAuth, useOrganizationList } from '@clerk/clerk-react';

import useNavigate from '../navigation/useNavigate';

const useIsAuthorized = () => {
  const {
    userId,
    isLoaded,
    orgId,
    signOut,
  } = useAuth();

  const { setActive, isLoaded: isOrgsLoaded, userMemberships } = useOrganizationList({
    userMemberships: {
      infinite: true,
    },
  });

  const { navigateToSignIn } = useNavigate();

  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    if (!isLoaded) {
      return;
    }

    if (!userId) {
      navigateToSignIn();
      return;
    }

    setIsAuthorized(true);
  }, [isLoaded, navigateToSignIn, userId]);

  useEffect(() => {
    if (orgId) {
      return;
    }

    if (!isAuthorized) {
      return;
    }

    if (!isOrgsLoaded) {
      return;
    }

    if (userMemberships.isLoading) {
      return;
    }

    if (userMemberships.data?.length) {
      setActive({ organization: userMemberships.data[0].organization.id });
      return;
    }

    signOut();
  }, [isAuthorized, isOrgsLoaded, orgId, setActive, signOut, userMemberships]);

  return {
    isAuthorized,
  };
};

export default useIsAuthorized;
