import { ColumnSetting } from '../components/columnSettingsModal/types';

const columnsSettingsStorageKey = 'calls-history-columns-settings';

export const saveColumnsSettingsToStorage = (columnsSettings: ColumnSetting[]) => {
  localStorage.setItem(columnsSettingsStorageKey, JSON.stringify(columnsSettings));
};

export const clearColumnsSettingsInStorage = () => {
  localStorage.removeItem(columnsSettingsStorageKey);
};

export const getColumnsSettingsFromStorage = (): ColumnSetting[] | undefined => {
  const rawColumnsSettings = localStorage.getItem(columnsSettingsStorageKey);

  if (!rawColumnsSettings) {
    return undefined;
  }

  return JSON.parse(rawColumnsSettings);
};
