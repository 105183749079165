import {
  Button,
  Flex,
  Form,
  Typography,
  Upload,
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import CampaignOperatorFields from '../campaignOperatorFields';
import useCallsFromSpreadsheetState from '../../hooks/useCallsFromSpreadsheetState';
import useCallsFromSpreadsheetLogic from '../../hooks/useCallsFromSpreadsheetLogic';
import { allowedFileTypes } from '../../constants';
import { OutboundCallState } from '../../types';
import { CampaignOperators } from '../../../../api/common/types';

import './index.scss';

interface UploadFileFormProps {
  campaignOperatorsLoading: boolean;
  data: OutboundCallState;
  campaignsOptions: string[];
  agentOptions: { key: number; label: string; }[];
  campaignOperator?: CampaignOperators;
  onUpdate: (fieldName: keyof OutboundCallState, value: string | boolean | number) => void;
}

const UploadFileForm = ({
  campaignOperatorsLoading,
  data,
  campaignsOptions,
  agentOptions,
  campaignOperator,
  onUpdate,
}: UploadFileFormProps) => {
  const {
    fileList,
    onRemove,
    onDrop,
    beforeUpload,
    setFileList,
  } = useCallsFromSpreadsheetState();

  const { uploading, onUpload } = useCallsFromSpreadsheetLogic({
    fileList,
    setFileList,
    campaignOperator,
  });

  return (
    <Flex vertical className="w-sm" gap={16}>
      <Form layout="vertical" className="w-sm">
        <CampaignOperatorFields
          disabled={campaignOperatorsLoading || uploading}
          data={data}
          campaignsOptions={campaignsOptions}
          agentOptions={agentOptions}
          onUpdate={onUpdate}
        />
        <Form.Item label="File" required>
          <Upload.Dragger
            accept={allowedFileTypes.join((' ,'))}
            fileList={fileList}
            beforeUpload={beforeUpload}
            onRemove={onRemove}
            onDrop={onDrop}
            maxCount={1}
          >
            <Flex vertical align="center" gap={16}>
              <InboxOutlined className="upload-icon" />
              <Typography.Title level={5} className="mt-0 mb-0">
                Click or drag file to this area to upload
              </Typography.Title>
              <Typography.Text>
                Supported file types: .csv, .xlsx
              </Typography.Text>
            </Flex>
          </Upload.Dragger>
        </Form.Item>
        <Form.Item className="mb-0">
          <Button
            className="w-full"
            type="primary"
            onClick={onUpload}
            disabled={!fileList.length || !campaignOperator}
            loading={uploading}
          >
            {uploading ? 'Uploading' : 'Start calls'}
          </Button>
        </Form.Item>
      </Form>
    </Flex>
  );
};

export default UploadFileForm;
