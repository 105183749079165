import { useCallback } from 'react';
import { DatePicker, Flex, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import { FilterProps } from '../../types';

const CallEndedFilter = ({ filters, onFiltersUpdate }: FilterProps) => {
  const onChanges = useCallback((dates: [Dayjs | null, Dayjs | null] | null) => {
    onFiltersUpdate(
      'callEndedFrom',
      dates?.[0]?.toISOString() ?? undefined,
      'callEndedTo',
      dates?.[1]?.toISOString() ?? undefined,
    );
  }, [onFiltersUpdate]);

  return (
    <Flex vertical gap={8}>
      <Typography.Text>Call ended range</Typography.Text>
      <DatePicker.RangePicker
        showTime
        value={filters.callEndedFrom && filters.callEndedTo
          ? [
            dayjs(filters.callEndedFrom),
            dayjs(filters.callEndedTo),
          ]
          : null}
        onChange={onChanges}
      />
    </Flex>
  );
};

export default CallEndedFilter;
