import { useCallback, useEffect, useState } from 'react';
import useCheckRole from '@marrlab-app-shared/hooks/auth';
import UserPermissions from '@marrlab-app-shared/constants/permissions';

import { getOrchestratorNumberAssignmentByAgentAndOrchestratorProviderIds } from '../../../api/callsHistory';
import { OrchestratorNumberAssignment } from '../../../api/common/types';

interface UseOrchestratorNumberAssignmentDataArgs {
  agentProviderId?: number;
  orchestratorProviderId?: string;
}

const useOrchestratorNumberAssignmentData = ({
  agentProviderId,
  orchestratorProviderId,
}: UseOrchestratorNumberAssignmentDataArgs) => {
  const { has } = useCheckRole();
  const canAccessInternalTools = has(UserPermissions.manageInternalTools);

  const [data, setData] = useState<OrchestratorNumberAssignment | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchOrchestratorNumberAssignment = useCallback(async () => {
    if (!canAccessInternalTools) {
      return;
    }

    if (!agentProviderId || !orchestratorProviderId) {
      return;
    }

    setLoading(true);
    try {
      const response = await getOrchestratorNumberAssignmentByAgentAndOrchestratorProviderIds(
        agentProviderId,
        orchestratorProviderId,
      );

      setData(response.data);
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  }, [agentProviderId, canAccessInternalTools, orchestratorProviderId]);

  useEffect(() => {
    fetchOrchestratorNumberAssignment();
  }, [fetchOrchestratorNumberAssignment]);

  return {
    data,
    loading,
  };
};

export default useOrchestratorNumberAssignmentData;
