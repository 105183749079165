import {
  Button,
  Card,
  Descriptions,
  Empty,
  Flex,
  Form,
  Select,
  Skeleton,
  Typography,
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useCheckRole } from '@marrlab-app-shared/hooks/auth';
import { UserPermissions } from '@marrlab-app-shared/constants/permissions';

import { CallHistory } from '../../api/callsHistory/types';
import useColumns from './hooks/useColumns';
import useNavigate from '../../hooks/navigation/useNavigate';
import useOrchestratorNumberAssignmentData from './hooks/useOrchestratorNumberAssignmentData';
import useCallbackLogData from './hooks/useCallbackLogData';

import './index.scss';

interface CallParamsProps {
  data: CallHistory | null;
  loading?: boolean;
}

const CallParams = ({ data, loading }: CallParamsProps) => {
  const { has } = useCheckRole();

  const canAccessInternalTools = has(UserPermissions.manageInternalTools);

  const { data: orchestratorNumberAssignment } = useOrchestratorNumberAssignmentData({
    agentProviderId: data?.agentProvider?.agentProviderId,
    orchestratorProviderId: data?.agentProvider?.orchestratorProviderId,
  });

  const {
    data: callSummary,
    callSummaryColumns,
    selectedCallbackLogIndex,
    loading: callSummaryLoading,
    onSelect,
  } = useCallbackLogData({
    callLogUuid: data?.callLogUuid,
  });

  const {
    commonColumns,
    twilioColumns,
    retellColumns,
    auxRetellColumns,
  } = useColumns({ data, orchestratorNumberAssignment });

  const { navigateBackOrToCallHistory } = useNavigate();

  return (
    <Card
      title={(
        <Flex align="center" gap={16}>
          <Button icon={<ArrowLeftOutlined />} onClick={navigateBackOrToCallHistory} />
          <Typography.Title level={4} className="call-params-title">Call parameters</Typography.Title>
        </Flex>
      )}
      className="bg-white rounded shadow-lg mb-6"
    >
      <Skeleton
        active
        title
        paragraph={{ rows: 10 }}
        className="mb-4"
        loading={loading}
      >
        <Typography.Title level={4} className="call-params-subtitle">Common</Typography.Title>
        <Descriptions
          items={commonColumns}
          column={2}
        />
      </Skeleton>
      {canAccessInternalTools && (
        <Skeleton
          active
          title
          paragraph={{ rows: 2 }}
          className="mb-4"
          loading={loading}
        >
          <Typography.Title level={4} className="call-params-subtitle">Twilio</Typography.Title>
          <Descriptions
            items={twilioColumns}
            column={2}
          />
        </Skeleton>
      )}
      {canAccessInternalTools && (
        <Skeleton
          active
          title
          paragraph={{ rows: 5 }}
          loading={loading}
        >
          <Typography.Title level={4} className="call-params-subtitle">Retell</Typography.Title>
          <Descriptions
            items={retellColumns}
            column={2}
          />
        </Skeleton>
      )}
      {canAccessInternalTools && (
        <Skeleton
          active
          title
          paragraph={{ rows: 2 }}
          loading={loading}
        >
          <Typography.Title level={4} className="call-params-subtitle">AUX Retell</Typography.Title>
          <Descriptions
            items={auxRetellColumns}
            column={2}
          />
        </Skeleton>
      )}
      <Skeleton
        active
        title
        paragraph={{ rows: 2 }}
        loading={loading || callSummaryLoading}
      >
        <Flex
          justify="space-between"
        >
          <Typography.Title
            level={4}
            className="call-params-subtitle"
          >
            Call summary
          </Typography.Title>
          {!!callSummary.length && (
            <Form.Item
              className="w-lg"
              label="Change call summary record:"
            >
              <Select
                value={selectedCallbackLogIndex}
                options={callSummary.map((item, index) => ({
                  label: `${item.timestamp} ${index === 0 ? '(default)' : ''}`,
                  value: index,
                }))}
                onChange={onSelect}
              />
            </Form.Item>
          )}
        </Flex>
        {callSummary.length ? (
          <Descriptions
            items={callSummaryColumns}
            column={2}
          />
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No call summary for that call"
          />
        )}
      </Skeleton>
    </Card>
  );
};

export default CallParams;
