import { Modifier } from '@dnd-kit/core';

// Doesn't allow to move any element in front of the first one
export const restrictToFirstElement: Modifier = ({
  transform,
  draggingNodeRect,
  scrollableAncestorRects,
}) => {
  const firstScrollableAncestorRect = scrollableAncestorRects[0];

  // Do nothing if no dragging rect or no scrollable ancestors
  if (!draggingNodeRect || !firstScrollableAncestorRect) {
    return transform;
  }

  // assuming all element have the same height
  const firstElementHeight = draggingNodeRect.height;

  // if current top position of draggable element less or equals
  // to top position of the container plus height of the first element,
  if (draggingNodeRect.top + transform.y <= firstScrollableAncestorRect.top + firstElementHeight) {
    return {
      ...transform,
      // push element back to that position
      y: firstScrollableAncestorRect.top + firstElementHeight - draggingNodeRect.top,
    };
  }
  return transform;
};

export default restrictToFirstElement;
