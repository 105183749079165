import { Form, Input, Select } from 'antd';

import { CampaignParams } from '../../types';
import { CampaignOperators } from '../../../../api/common/types';

import './index.scss';

export interface AgentParamsProps {
  data: CampaignParams;
  agentParams: CampaignOperators['agentParams'];
  disabled: boolean;
  onUpdate: (propName: keyof CampaignParams, value: string) => void;
}

const AgentParamsFields = ({
  data,
  agentParams,
  disabled,
  onUpdate,
}: AgentParamsProps) => (
  <>
    {agentParams.map((agentParam) => {
      if (agentParam.type === 'text') {
        return (
          <Form.Item className="campaign-param-field" label={agentParam.name} key={agentParam.key}>
            <Input
              value={data[agentParam.key]}
              placeholder={agentParam.placeholder}
              onChange={(event) => onUpdate(agentParam.key, event.target.value)}
              disabled={disabled}
            />
          </Form.Item>
        );
      }

      if (agentParam.type === 'select') {
        return (
          <Form.Item className="campaign-param-field" key={agentParam.key} label={agentParam.name}>
            <Select
              value={data[agentParam.key]}
              onChange={(value) => onUpdate(agentParam.key, value)}
              options={agentParam.options?.map((option) => ({ label: option, value: option }))}
              disabled={disabled}
            />
          </Form.Item>
        );
      }

      return null;
    })}
  </>
);

export default AgentParamsFields;
