import { useCallback } from 'react';
import { Flex, Select, Typography } from 'antd';

import { FilterProps } from '../../types';

const options = [
  { value: 0, label: 'Inbound' },
  { value: 1, label: 'Outbound' },
];

const CallDirectionFilter = ({ filters, onFiltersUpdate }: FilterProps) => {
  const onChange = useCallback((value?: 0 | 1) => {
    onFiltersUpdate('callDirection', value);
  }, [onFiltersUpdate]);

  return (
    <Flex vertical gap={8} className="min-w-48">
      <Typography.Text>Call direction</Typography.Text>
      <Select
        placeholder="Any"
        value={filters.callDirection}
        onChange={onChange}
        options={options}
        allowClear
      />
    </Flex>
  );
};

export default CallDirectionFilter;
