import { useCallback } from 'react';
import { Flex, Select, Typography } from 'antd';

import { FilterProps } from '../../types';
import { CallStatusEnum, CallStatusNames } from '../../../../constants';

const options = [
  {
    value: CallStatusEnum.Busy,
    label: CallStatusNames[CallStatusEnum.Busy],
  },
  {
    value: CallStatusEnum.Completed,
    label: CallStatusNames[CallStatusEnum.Completed],
  },
  {
    value: CallStatusEnum.InProgress,
    label: CallStatusNames[CallStatusEnum.InProgress],
  },
  {
    value: CallStatusEnum.NoAnswer,
    label: CallStatusNames[CallStatusEnum.NoAnswer],
  },
];

const CallStatusFilter = ({ filters, onFiltersUpdate }: FilterProps) => {
  const onChange = useCallback((value: string) => {
    onFiltersUpdate('callStatus', value);
  }, [onFiltersUpdate]);

  return (
    <Flex vertical gap={8} className="min-w-48">
      <Typography.Text>Call status</Typography.Text>
      <Select
        placeholder="Any status"
        value={filters.callStatus}
        onChange={onChange}
        options={options}
        allowClear
      />
    </Flex>
  );
};

export default CallStatusFilter;
