import { Typography } from 'antd';

import './index.scss';

const Statements = () => (
  <div className="py-3">
    <Typography.Title level={4} className="statement-title">Initiate a new call</Typography.Title>
    <Typography.Paragraph className="statement-description">
      Clicking on the &quot;Start a call&quot; button will initiate the call immediately!
    </Typography.Paragraph>
  </div>
);

export default Statements;
