import { useCallback, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import cn from 'classnames';
import {
  Form,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
} from 'antd';

import { OutboundCallState } from '../../types';
import { HumanBackup } from '../../../../api/outboundCall/types';

interface HumanBackupFieldProps {
  humanBackupList?: HumanBackup[];
  humanBackupPhone: string;
  disabled: boolean;
  loading: boolean;
  onUpdate: (fieldName: keyof OutboundCallState, value: string | boolean) => void;
}

enum FieldStateOptionsEnum {
  predefined = 'predefined',
  plain = 'plain',
  without = 'without',
}

const fieldStateOptions = [
  { label: 'Predefined', value: FieldStateOptionsEnum.predefined },
  { label: 'Phone number', value: FieldStateOptionsEnum.plain },
  { label: 'Without', value: FieldStateOptionsEnum.without },
];

const HumanBackupField = ({
  humanBackupList,
  humanBackupPhone,
  onUpdate,
  disabled,
  loading,
}: HumanBackupFieldProps) => {
  const [fieldState, setFieldState] = useState(FieldStateOptionsEnum.without);

  const withoutHumanBackup = fieldState === FieldStateOptionsEnum.without;

  const onFieldStateChange = useCallback((event: RadioChangeEvent) => {
    const { value } = event.target;

    setFieldState(value);
    onUpdate('humanBackupPhone', '');
    onUpdate('humanBackup', value !== FieldStateOptionsEnum.without);
  }, [onUpdate]);

  const options = humanBackupList?.map((item) => ({
    label: item.name,
    value: item.phoneNumber,
  })) ?? [];

  return (
    <Form.Item label="Human backup" required={!withoutHumanBackup}>
      <Space direction="vertical" className="w-full">
        <Radio.Group
          options={fieldStateOptions}
          value={fieldState}
          onChange={onFieldStateChange}
          disabled={disabled}
        />
        {(fieldState === FieldStateOptionsEnum.predefined || withoutHumanBackup) && (
          <Select
            value={withoutHumanBackup ? '' : humanBackupPhone}
            onChange={(value: string) => onUpdate('humanBackupPhone', value)}
            options={options}
            loading={loading}
            disabled={disabled || withoutHumanBackup}
          />
        )}
        {fieldState === FieldStateOptionsEnum.plain && (
          <PhoneInput
            containerClass={cn('phone-input', { disabled })}
            value={humanBackupPhone}
            onChange={(value: string) => onUpdate('humanBackupPhone', value)}
            country="us"
            disabled={disabled}
          />
        )}
      </Space>
    </Form.Item>
  );
};

export default HumanBackupField;
