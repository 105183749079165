import { DescriptionsItemType } from 'antd/es/descriptions';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import AudioUrlCell from '@marrlab-app-shared/components/audioUrlCell';
import { formatDate } from '@marrlab-app-shared/utils/date/date';

import { CallStatusNames } from '../../../../constants';
import { formatDuration } from '../../../../utils/date/time';
import { CallHistory } from '../../../../api/callsHistory/types';
import { averageRatingRenderer } from '../../../../utils/annotations';
import CampaignParamsCell from '../../../callsHistory/components/campaignParamsCell';
import LongTextCell from '../../../../components/longTextCell';
import { getPreSignedAudioUrl } from '../../../../api/callsHistory';
import { OrchestratorNumberAssignment } from '../../../../api/common/types';

interface CellRendererProps {
  column: DescriptionsItemType;
  data?: CallHistory | null;
  orchestratorNumberAssignment?: OrchestratorNumberAssignment | null;
}

const CellRenderer = ({ column, data, orchestratorNumberAssignment }: CellRendererProps) => {
  if (!data) {
    return '';
  }

  if (column.key === 'callStatus') {
    return CallStatusNames[data.callStatus] ?? data.callStatus;
  }

  if (column.key === 'orchestratorNumberAssignment') {
    return orchestratorNumberAssignment?.orchestratorNumberAssignmentId;
  }

  if (column.key === 'callDirection') {
    return data.callDirection === 0 ? 'Inbound' : 'Outbound';
  }

  if (column.key === 'startDate') {
    return formatDate(data.startDate as unknown as string, true);
  }

  if (column.key === 'endDate') {
    return formatDate(data.endDate as unknown as string, true);
  }

  if (column.key === 'callDurationSec') {
    return formatDuration(data.callDurationSec ?? undefined);
  }

  if (column.key === 'audioUrl') {
    return (
      <AudioUrlCell
        value={data.audioUrl ?? undefined}
        callId={data.callLogId}
        getPreSignedAudioUrl={getPreSignedAudioUrl}
      />
    );
  }

  if (column.key === 'transferAudioUrl') {
    return (
      <AudioUrlCell
        value={data.transferAudioUrl ?? undefined}
        callId={data.callLogId}
        getPreSignedAudioUrl={getPreSignedAudioUrl}
      />
    );
  }

  if (column.key === 'campaignJson') {
    return (
      <CampaignParamsCell
        orchestratorProvider={data.orchestratorProvider}
        promptParameters={data.promptParameters}
        agentProvider={data.agentProvider}
      />
    );
  }

  if (column.key === 'user') {
    return data.user?.number;
  }

  if (column.key === 'campaign') {
    return data.orchestratorProvider?.campaign?.displayName;
  }

  if (column.key === 'customer') {
    return data.orchestratorProvider?.campaign?.customer?.name;
  }

  if (column.key === 'orchestratorProvider') {
    return data.orchestratorProvider?.orchestratorProviderId;
  }

  if (column.key === 'humanLikeRating') {
    return averageRatingRenderer(data.annotations);
  }

  if (column.key === 'retellAgentId') {
    return data.retellCallAttributes?.agent_id;
  }

  if (column.key === 'audioProtocol') {
    return data.retellCallAttributes?.audio_websocket_protocol;
  }

  if (column.key === 'callCompletion') {
    const callAnalysis = data.retellCallAttributes?.call_analysis;

    if (!callAnalysis) {
      return null;
    }

    return (
      <Tooltip
        title={callAnalysis?.call_completion_rating_reason}
      >
        {callAnalysis?.call_completion_rating}
        {' '}
        <QuestionCircleOutlined className="call-params-hint-icon" />
      </Tooltip>
    );
  }

  if (column.key === 'taskCompletion') {
    const callAnalysis = data.retellCallAttributes?.call_analysis;

    if (!callAnalysis) {
      return null;
    }

    return (
      <Tooltip
        title={callAnalysis?.agent_task_completion_rating_reason}
      >
        {callAnalysis?.agent_task_completion_rating}
        {' '}
        <QuestionCircleOutlined className="call-params-hint-icon" />
      </Tooltip>
    );
  }

  if (column.key === 'userSentiment') {
    return data.retellCallAttributes?.call_analysis?.user_sentiment;
  }

  if (column.key === 'disconnectionReason') {
    return data.retellCallAttributes?.disconnection_reason;
  }

  if (column.key === 'averageEndToEndLatency') {
    const e2eLatency = data.retellCallAttributes?.e2e_latency;
    const medianE2eLatency = e2eLatency?.p50 ? `${Math.round(e2eLatency.p50)} ms` : '';
    const minE2eLatency = e2eLatency?.min ? `${Math.round(e2eLatency.min)} ms` : '';

    const llmLatency = data.retellCallAttributes?.llm_latency;
    const medianLlmLatency = llmLatency?.p50 ? `${Math.round(llmLatency.p50)} ms` : '';
    const minLlmLatency = llmLatency?.min ? `${Math.round(llmLatency.min)} ms` : '';

    if (!data.retellCallAttributes?.e2e_latency) {
      return null;
    }

    return (
      <Tooltip
        title={(
          <>
            End to end latency:
            <br />
            median:
            {' '}
            {medianE2eLatency}
            <br />
            min:
            {' '}
            {minE2eLatency}
            <br />
            <br />
            LLM latency:
            <br />
            median:
            {' '}
            {medianLlmLatency}
            <br />
            min:
            {' '}
            {minLlmLatency}
          </>
        )}
      >
        {medianE2eLatency}
        {' '}
        <QuestionCircleOutlined className="call-params-hint-icon" />
      </Tooltip>
    );
  }

  if (column.key === 'summary') {
    return data.retellCallAttributes?.call_analysis?.call_summary;
  }

  if (column.key === 'customerCallId') {
    return data.customerCreateCallApiRequest?.customerCallId;
  }

  if (column.key === 'callAction') {
    return (
      <LongTextCell
        value={data.callAction ? JSON.stringify(data.callAction, null, 2) : undefined}
        title="Call actions"
        width="w-md"
      />
    );
  }

  if (column.key === 'auxRetellCallAttributes') {
    return (
      <LongTextCell
        value={data.auxRetellCallAttributes
          ? JSON.stringify(data.auxRetellCallAttributes, null, 2)
          : undefined}
        title="AUX Retell call attributes"
        width="w-screen-md"
      />
    );
  }

  return <div>{data[column.key as keyof CallHistory] as string}</div>;
};

export default CellRenderer;
