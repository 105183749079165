import { Form, Select } from 'antd';

import { OutboundCallState } from '../../types';

interface UploadFileFormProps {
  disabled: boolean;
  data: OutboundCallState;
  campaignsOptions: string[];
  agentOptions: { key: number; label: string; }[];
  onUpdate: (fieldName: keyof OutboundCallState, value: string | boolean | number) => void;
}

const CampaignOperatorFields = ({
  disabled,
  data,
  campaignsOptions,
  agentOptions,
  onUpdate,
}: UploadFileFormProps) => (
  <>
    <Form.Item label="Campaign" required>
      <Select
        value={data.campaign}
        onChange={(value: string) => onUpdate('campaign', value)}
        options={campaignsOptions.map((item) => ({ label: item, value: item }))}
        disabled={disabled}
      />
    </Form.Item>
    <Form.Item label="Agent alias" required>
      <Select
        value={data.agentAliasId}
        onChange={(value: number) => onUpdate('agentAliasId', value)}
        options={agentOptions.map((item) => ({
          label: item.label,
          value: item.key,
        }))}
        disabled={disabled}
      />
    </Form.Item>
  </>
);

export default CampaignOperatorFields;
