import { useCallback, useState, MouseEventHandler } from 'react';
import { TableColumnsType, TableProps } from 'antd';
import { useCheckRole } from '@marrlab-app-shared/hooks/auth';
import { UserPermissions } from '@marrlab-app-shared/constants/permissions';

import { CallHistory } from '../../../api/callsHistory/types';
import { callUuidClassName, columnsInitialState } from '../constants/columnsInitialState';
import { mapColumnsSettingsToColumns } from '../components/columnSettingsModal/utils/mappers';
import { getColumnsSettingsFromStorage } from '../utils/columnsSettingsPersistence';
import useNavigate from '../../../hooks/navigation/useNavigate';

const sticky: TableProps<CallHistory>['sticky'] = { offsetHeader: 0 };

const scroll: TableProps<CallHistory>['scroll'] = { x: 'max-content' };

const useTableConfiguration = () => {
  const { has } = useCheckRole();

  const canAccessInternalTools = has(UserPermissions.manageInternalTools);

  const {
    navigateToCallDetailsInNewTab,
    navigateToCallDetailsByUuidInNewTab,
  } = useNavigate();

  const filteredColumnsInitialState = columnsInitialState.filter((column) => {
    if (canAccessInternalTools) {
      return true;
    }

    return column.availableForExternal;
  });

  const [columns, setColumns] = useState<TableColumnsType<CallHistory>>(() => (
    mapColumnsSettingsToColumns({
      columns: filteredColumnsInitialState,
      columnsSettings: getColumnsSettingsFromStorage(),
    })
  ));

  const onRow = useCallback((record: CallHistory) => ({
    onClick: ((event) => {
      const td = (event.target as Element).closest('td');

      if (!td) {
        return;
      }

      if (td.classList.contains(callUuidClassName)) {
        navigateToCallDetailsByUuidInNewTab(record.callLogUuid);
        return;
      }

      navigateToCallDetailsInNewTab(record.callLogId);
    }) as MouseEventHandler,
  }), [navigateToCallDetailsByUuidInNewTab, navigateToCallDetailsInNewTab]);

  return {
    columns,
    sticky,
    scroll,
    setColumns,
    onRow,
  };
};

export default useTableConfiguration;
