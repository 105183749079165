import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { TableColumnsType } from 'antd';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';

import { mapColumnsToColumnsSettings } from '../utils/mappers';
import { ColumnSetting } from '../types';
import { CallHistory } from '../../../../../api/callsHistory/types';

interface UseColumnsModalStateArgs {
  isOpen: boolean;
  initialColumns: TableColumnsType<CallHistory>;
}

const useColumnsModalState = ({
  isOpen,
  initialColumns,
}: UseColumnsModalStateArgs) => {
  const [columnsSettings, setColumnsSettings] = useState<ColumnSetting[]>([]);

  const allVisible = useMemo(() => (
    columnsSettings.every((column) => column.visible)
  ), [columnsSettings]);

  useEffect(() => {
    if (isOpen) {
      setColumnsSettings(mapColumnsToColumnsSettings(initialColumns));
    } else {
      setColumnsSettings([]);
    }
  }, [initialColumns, isOpen]);

  const onVisibilityUpdate = useCallback((key: string, visible: boolean) => {
    setColumnsSettings((state) => state.map((column) => {
      if (column.key === key) {
        return { ...column, visible };
      }
      return column;
    }));
  }, []);

  const onAllVisibleUpdate = useCallback(() => {
    setColumnsSettings((state) => state.map((column) => ({
      ...column,
      visible: !allVisible,
    })));
  }, [allVisible]);

  const onDragEnd = useCallback(({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setColumnsSettings((previous) => {
        const activeIndex = previous.findIndex((item) => item.key === active.id);
        const overIndex = previous.findIndex((item) => item.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  }, []);

  return {
    allVisible,
    columnsSettings,
    onVisibilityUpdate,
    onAllVisibleUpdate,
    onDragEnd,
  };
};

export default useColumnsModalState;
