import { TableColumnsType } from 'antd';
import { formatMinutesDuration } from '@marrlab-app-shared/utils/date/time';

import LongTextCell from '../../../components/longTextCell';
import { formatDateWithMilliseconds } from '../../../utils/date/time';
import { CoordinatorCallEventTableRow } from '../types';

export const idColumnKey = 'coordinatorCallEventId';

export const columnsInitialState: TableColumnsType<CoordinatorCallEventTableRow> = [
  {
    title: 'ID',
    dataIndex: idColumnKey,
    key: idColumnKey,
    fixed: 'left',
    sorter: true,
    sortOrder: 'ascend',
    width: 80,
  },
  {
    title: 'Timestamp',
    dataIndex: 'timestamp',
    key: 'timestamp',
    sorter: true,
    width: 220,
    render: (time: string) => formatDateWithMilliseconds(time),
  },
  {
    title: 'Call time',
    dataIndex: 'callTime',
    key: 'callTime',
    width: 90,
    render: (time?: number) => formatMinutesDuration(time),
  },
  {
    title: 'Event type',
    dataIndex: 'eventType',
    key: 'eventType',
    sorter: true,
    width: 200,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    sorter: true,
    width: 110,
  },
  {
    title: 'Event details',
    dataIndex: 'event_details',
    key: 'event_details',
    width: 200,
    render: (value: Record<string, unknown>) => (
      <LongTextCell
        value={value ? JSON.stringify(value, null, 2) : undefined}
        title="Event details"
      />
    ),
  },
  {
    title: 'Error details',
    dataIndex: 'error_details',
    key: 'error_details',
    sorter: true,
    width: 200,
    render: (value: string) => (
      <LongTextCell
        value={value}
        title="Error details"
      />
    ),
  },
];
