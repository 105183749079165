import { useMemo } from 'react';
import useDataFetch from '@marrlab-app-shared/hooks/data/useDataFetch';

import { campaignOperatorsRequest } from '../../../api/outboundCall';

const useCampaignOperators = () => {
  const { loading, data } = useDataFetch({
    request: campaignOperatorsRequest,
  });

  const campaignOperators = useMemo(() => (
    (data ?? []).filter((item) => !!item.orchestratorProvider.campaign)
  ), [data]);

  const campaignsOptions = useMemo(() => (
    [...new Set(campaignOperators.map((campaign) => (
      campaign.orchestratorProvider.campaign?.displayName ?? ''
    )))]
  ), [campaignOperators]);

  const agentOptionsMap = useMemo(() => (
    campaignOperators.reduce((acc, campaign) => {
      const key = campaign.orchestratorProvider.campaign?.displayName ?? '';

      if (!acc[key]) {
        acc[key] = [];
      }

      acc[key].push({
        key: campaign.orchestratorNumberAssignmentId,
        label: campaign.orchestratorProvider.config.AgentName,
      });
      return acc;
    }, {} as Record<string, { key: number; label: string; }[]>)
  ), [campaignOperators]);

  return {
    loading,
    campaignsOptions,
    agentOptionsMap,
    campaignOperators,
  };
};

export default useCampaignOperators;
