import { useMemo } from 'react';
import { DescriptionsProps } from 'antd';
import useCheckRole from '@marrlab-app-shared/hooks/auth';
import UserPermissions from '@marrlab-app-shared/constants/permissions';

import CellRenderer from '../components/cellRenderer';
import { CallHistory } from '../../../api/callsHistory/types';
import {
  Column,
  initialAuxRetellColumns,
  initialCommonColumns,
  initialRetellColumns,
  initialTwilioColumns,
} from '../constants';
import { OrchestratorNumberAssignment } from '../../../api/common/types';

interface UseCallDataArgs {
  data?: CallHistory | null;
  orchestratorNumberAssignment?: OrchestratorNumberAssignment | null;
}

interface Columns {
  commonColumns: DescriptionsProps['items'];
  twilioColumns: DescriptionsProps['items'];
  retellColumns: DescriptionsProps['items'];
  auxRetellColumns: DescriptionsProps['items'];
}

const useColumns = ({ data, orchestratorNumberAssignment }: UseCallDataArgs): Columns => {
  const { has } = useCheckRole();

  const canAccessInternalTools = has(UserPermissions.manageInternalTools);

  const columns: Columns = useMemo(() => {
    const columnMapper = (acc: Column[], column: Column) => {
      if (!canAccessInternalTools && !column.availableForExternal) {
        return acc;
      }

      acc.push({
        ...column,
        children: (
          <CellRenderer
            data={data}
            orchestratorNumberAssignment={orchestratorNumberAssignment}
            column={column}
          />
        ),
      });

      return acc;
    };

    const commonColumns = initialCommonColumns.reduce(columnMapper, []);
    const twilioColumns = initialTwilioColumns.reduce(columnMapper, []);
    const retellColumns = initialRetellColumns.reduce(columnMapper, []);
    const auxRetellColumns = initialAuxRetellColumns.reduce(columnMapper, []);

    return {
      commonColumns,
      twilioColumns,
      retellColumns,
      auxRetellColumns,
    };
  }, [canAccessInternalTools, data, orchestratorNumberAssignment]);

  return columns;
};

export default useColumns;
