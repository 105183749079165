import { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { AxiosError } from 'axios';

import { GetCoordinatorCallEventsParams } from '../../../api/coordinatorCallEvents/types';
import { getCoordinatorCallEvents } from '../../../api/coordinatorCallEvents';
import { calculateDurationFromCreatedEvent } from '../utils';
import { CoordinatorCallEventTableRow } from '../types';

const useCoordinatorCallEventsData = (callUuid: string, {
  sortBy,
  order,
}: GetCoordinatorCallEventsParams) => {
  const [data, setData] = useState<CoordinatorCallEventTableRow[] | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchCoordinatorCallEvents = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getCoordinatorCallEvents(callUuid, {
        sortBy,
        order,
      });

      setData(calculateDurationFromCreatedEvent(response.data));
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        message.open({ content: error.message, type: 'error', duration: 10 });
      }
    }

    setLoading(false);
  }, [callUuid, order, sortBy]);

  useEffect(() => {
    fetchCoordinatorCallEvents();
  }, [fetchCoordinatorCallEvents]);

  return {
    data,
    loading,
  };
};

export default useCoordinatorCallEventsData;
