import {
  Button,
  Card,
  Flex,
  Pagination,
  Table,
  Typography,
} from 'antd';
import { CloseOutlined, ReloadOutlined, SettingOutlined } from '@ant-design/icons';
import useSearchDebounce from '@marrlab-app-shared/hooks/state/useSearchDebounce';
import FiltersPanel from '@marrlab-app-shared/components/filtersPanel';
import UserPermissions from '@marrlab-app-shared/constants/permissions';
import useCheckRole from '@marrlab-app-shared/hooks/auth';

import Search from './components/search';
import CallDirectionFilter from './components/callDirectionFilter';
import CallStatusFilter from './components/callStatusFilter';
import CampaignFilter from './components/campaignFilter';
// import UserFilter from './components/userFilter';
import CustomerFilter from './components/customerFilter';
import CallStartedFilter from './components/callStartedFilter';
import CallEndedFilter from './components/callEndedFilter';
import ColumnSettingsModal from './components/columnSettingsModal';

import useColumnsSettingsModal from './hooks/useColumnsSettingsModal';
import useCallsHistoryData from './hooks/useCallsHistoryData';
import usePagination from './hooks/usePagination';
import useSorting from './hooks/useSorting';
import useTableConfiguration from './hooks/useTableConfiguration';
import useFilters from './hooks/useFilters';
import { idColumnKey } from './constants/columnsInitialState';
import { getDefaultSearch } from './utils/searchParams';

import './index.scss';

const CallsHistory = () => {
  const { has } = useCheckRole();
  const canAccessInternalTools = has(UserPermissions.manageInternalTools);

  const {
    columns,
    sticky,
    scroll,
    setColumns,
    onRow,
  } = useTableConfiguration();
  const {
    skip,
    page,
    pageSize,
    resetPage,
    onPageChange,
    showTotal,
  } = usePagination();
  const { search, updateSearch } = useSearchDebounce({
    onDebounce: resetPage,
    defaultSearch: getDefaultSearch(),
  });
  const { filters, onFiltersUpdate, clearFilters } = useFilters({ resetPage });
  const { sortBy, sortDirection, onSortChange } = useSorting({ setColumns });
  const { data, loading, fetchCallsHistory } = useCallsHistoryData({
    skip,
    take: pageSize,
    sortBy,
    order: sortDirection,
    search,
    ...filters,
  });
  const columnsSettingsModal = useColumnsSettingsModal({ setColumns });

  return (
    <Card
      title={(
        <Typography.Title level={4} className="call-history-title">
          Calls history
        </Typography.Title>
      )}
      className="bg-white rounded shadow-lg"
    >
      <FiltersPanel
        search={(
          <Flex gap={16}>
            <Search
              updateSearch={updateSearch}
              defaultSearch={search}
            />
            <Button icon={<ReloadOutlined />} onClick={fetchCallsHistory}>Reload</Button>
            <Button
              className="ml-auto"
              icon={<SettingOutlined />}
              onClick={columnsSettingsModal.open}
            >
              Columns settings
            </Button>
          </Flex>
        )}
      >
        <Flex gap={16} vertical>
          <Flex gap={16} wrap="wrap">
            <CallStatusFilter filters={filters} onFiltersUpdate={onFiltersUpdate} />
            <CallDirectionFilter filters={filters} onFiltersUpdate={onFiltersUpdate} />
            {/* <UserFilter filters={filters} onFiltersUpdate={onFiltersUpdate} /> */}
            {canAccessInternalTools && (
              <CustomerFilter filters={filters} onFiltersUpdate={onFiltersUpdate} />
            )}
            <CampaignFilter filters={filters} onFiltersUpdate={onFiltersUpdate} />
          </Flex>
          <Flex gap={16} wrap="wrap">
            <CallStartedFilter filters={filters} onFiltersUpdate={onFiltersUpdate} />
            <CallEndedFilter filters={filters} onFiltersUpdate={onFiltersUpdate} />
          </Flex>
          <Flex justify="flex-end">
            <Button danger onClick={clearFilters} icon={<CloseOutlined />}>Clear filters</Button>
          </Flex>
        </Flex>
      </FiltersPanel>
      <div className="table-wrapper">
        <Table
          rowKey={idColumnKey}
          dataSource={data?.data}
          columns={columns}
          loading={loading}
          scroll={scroll}
          pagination={false}
          sticky={sticky}
          onChange={onSortChange}
          onRow={onRow}
        />
      </div>
      <Flex justify="flex-end">
        <Pagination
          hideOnSinglePage
          className="mt-4"
          total={data?.total}
          showTotal={showTotal}
          pageSize={pageSize}
          current={page}
          showSizeChanger
          onChange={onPageChange}
          disabled={loading}
        />
      </Flex>
      <ColumnSettingsModal
        isOpen={columnsSettingsModal.isOpen}
        onConfirm={columnsSettingsModal.onConfirm}
        onCancel={columnsSettingsModal.onCancel}
        initialColumns={columns}
      />
    </Card>
  );
};

export default CallsHistory;
