import { Fragment } from 'react';
import { Popover, Typography } from 'antd';

import { CallHistory } from '../../../../api/callsHistory/types';

import './index.scss';

interface CampaignParamsCellProps {
  orchestratorProvider: CallHistory['orchestratorProvider'];
  promptParameters: CallHistory['promptParameters'];
  agentProvider: CallHistory['agentProvider'];
}

const CampaignParamsCell = ({
  orchestratorProvider,
  promptParameters,
  agentProvider,
}: CampaignParamsCellProps) => {
  if (!orchestratorProvider && !promptParameters && !agentProvider) {
    return null;
  }

  return (
    <Popover
      overlayClassName="campaign-params-cell-popover"
      content={(
        <>
          {orchestratorProvider && (
            <>
              <Typography.Title
                level={5}
                className="whitespace-pre-wrap"
              >
                Orchestration provider
              </Typography.Title>
              <Typography.Text strong className="whitespace-pre-wrap">
                Provider type:
              </Typography.Text>
              <br />
              <Typography.Text
                className="whitespace-pre-wrap"
              >
                {orchestratorProvider?.providerType}
              </Typography.Text>
              <br />
              <Typography.Text strong className="whitespace-pre-wrap">
                Agent id:
              </Typography.Text>
              <br />
              <Typography.Text
                className="whitespace-pre-wrap"
              >
                {orchestratorProvider?.config.AgentId}
              </Typography.Text>
              <br />
              <Typography.Text strong className="whitespace-pre-wrap">
                Agent name:
              </Typography.Text>
              <br />
              <Typography.Text
                className="whitespace-pre-wrap"
              >
                {orchestratorProvider?.config.AgentName}
              </Typography.Text>
              <br />
              <Typography.Text strong className="whitespace-pre-wrap">
                LLM websocket:
              </Typography.Text>
              <br />
              <Typography.Text
                className="whitespace-pre-wrap"
              >
                {orchestratorProvider?.config.LlmWebsocket}
              </Typography.Text>
              <br />
              <Typography.Text strong className="whitespace-pre-wrap">
                Voice ID:
              </Typography.Text>
              <br />
              <Typography.Text
                className="whitespace-pre-wrap"
              >
                {orchestratorProvider?.config.VoiceId}
              </Typography.Text>
            </>
          )}
          {promptParameters && (
            <>
              <Typography.Title
                level={5}
                className="whitespace-pre-wrap"
              >
                Prompt params:
              </Typography.Title>
              {Object.entries(promptParameters).map(([key, value]) => (
                <Fragment key={key}>
                  <Typography.Text strong className="whitespace-pre-wrap">
                    {key}
                    :
                    {' '}
                  </Typography.Text>
                  <Typography.Text
                    className="whitespace-pre-wrap"
                  >
                    {value}
                  </Typography.Text>
                  <br />
                </Fragment>
              ))}
            </>
          )}
          {agentProvider && (
            <>
              <Typography.Title
                level={5}
                className="whitespace-pre-wrap"
              >
                Agent provider:
              </Typography.Title>
              <Typography.Text strong className="whitespace-pre-wrap">
                Agent provider ID:
                {' '}
              </Typography.Text>
              <Typography.Text
                className="whitespace-pre-wrap"
              >
                {agentProvider.agentProviderId}
              </Typography.Text>
              <br />
              <Typography.Text strong className="whitespace-pre-wrap">
                Provider type:
                {' '}
              </Typography.Text>
              <Typography.Text
                className="whitespace-pre-wrap"
              >
                {agentProvider.providerType}
              </Typography.Text>
              <br />
              <Typography.Text strong className="whitespace-pre-wrap">
                API version:
                {' '}
              </Typography.Text>
              <Typography.Text
                className="whitespace-pre-wrap"
              >
                {agentProvider.apiVersion}
              </Typography.Text>
              <br />
              <Typography.Text strong className="whitespace-pre-wrap">
                Class:
                {' '}
              </Typography.Text>
              <Typography.Text
                className="whitespace-pre-wrap"
              >
                {agentProvider.class}
              </Typography.Text>
              <br />
              <Typography.Text strong className="whitespace-pre-wrap">
                Model:
                {' '}
              </Typography.Text>
              <Typography.Text
                className="whitespace-pre-wrap"
              >
                {agentProvider.model}
              </Typography.Text>
              <br />
              <Typography.Text strong className="whitespace-pre-wrap">
                Function tools:
                {' '}
              </Typography.Text>
              <Typography.Text
                className="whitespace-pre-wrap"
              >
                {JSON.stringify(agentProvider.functionTools)}
              </Typography.Text>
              <br />
              <Typography.Text strong className="whitespace-pre-wrap">
                integration ID:
                {' '}
              </Typography.Text>
              <Typography.Text
                className="whitespace-pre-wrap"
              >
                {agentProvider.integrationId}
              </Typography.Text>
              <br />
              <Typography.Text strong className="whitespace-pre-wrap">
                Websocket:
                {' '}
              </Typography.Text>
              <Typography.Text
                className="whitespace-pre-wrap"
              >
                {agentProvider.websocket}
              </Typography.Text>
              <br />
              <Typography.Text strong className="whitespace-pre-wrap">
                Vector DB config:
                {' '}
              </Typography.Text>
              <Typography.Text
                className="whitespace-pre-wrap"
              >
                {JSON.stringify(agentProvider.vectorDbData)}
              </Typography.Text>
              <br />
              <Typography.Text strong className="whitespace-pre-wrap">
                Vector DB date:
                {' '}
              </Typography.Text>
              <Typography.Text
                className="whitespace-pre-wrap"
              >
                {JSON.stringify(agentProvider.vectorDbData)}
              </Typography.Text>
              <br />
              <Typography.Text strong className="whitespace-pre-wrap">
                Prompt:
                {' '}
              </Typography.Text>
              <Typography.Text
                className="whitespace-pre-wrap"
              >
                {agentProvider.prompt}
              </Typography.Text>
              <br />
              <Typography.Text strong className="whitespace-pre-wrap">
                Multiple prompts:
                {' '}
              </Typography.Text>
              <Typography.Text
                className="whitespace-pre-wrap"
              >
                {agentProvider.multiplePrompts?.join('\n')}
              </Typography.Text>
              <br />
              <Typography.Text strong className="whitespace-pre-wrap">
                LLM Prompt:
                {' '}
              </Typography.Text>
              <Typography.Text
                className="whitespace-pre-wrap"
              >
                {agentProvider.llmPrompt}
              </Typography.Text>
            </>
          )}
        </>
      )}
      title={<Typography.Title level={5} className="mt-0">Campaign params</Typography.Title>}
    >
      <Typography.Text
        ellipsis
        className="w-48"
      >
        {orchestratorProvider && (
          <>
            <Typography.Text strong>
              Agent name:
              {' '}
            </Typography.Text>
            <Typography.Text>{orchestratorProvider.config.AgentName}</Typography.Text>
          </>
        )}
        {!orchestratorProvider && promptParameters && (
          <Typography.Text className="whitespace-pre-wrap">Prompt params</Typography.Text>
        )}
      </Typography.Text>
    </Popover>
  );
};

export default CampaignParamsCell;
