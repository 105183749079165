import React from 'react';
import { App, ConfigProvider } from 'antd';
import 'virtual:windi.css';
import ReactDOM from 'react-dom/client';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { AudioPlayerProvider } from '@marrlab-app-shared/components/audioPlayer';

import Router from './router';

import configureSentry from './utils/sentry';
import './utils/axious';

import './index.css';

configureSentry();

dayjs.extend(duration);
dayjs.extend(localizedFormat);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App>
      <ConfigProvider
        theme={{
          components: {
            Layout: {
              bodyBg: 'white',
              footerBg: 'white',
              headerBg: 'white',
              headerColor: '#36454F',
            },
          },
          token: {
            // Seed Token
            colorPrimary: '#337ab7',
            borderRadius: 6,

            // Alias Token
            colorBgContainer: 'white',
          },
        }}
      >
        <AudioPlayerProvider>
          <Router />
        </AudioPlayerProvider>
      </ConfigProvider>
    </App>
  </React.StrictMode>,
);
