import { useCallback } from 'react';
import useDataFetch from '@marrlab-app-shared/hooks/data/useDataFetch';

import {
  getCallDetails,
  getCallDetailsByCustomerId,
  getCallDetailsByUuid,
  getCallDetailsByUuidAndCustomerId,
} from '../../../api/callsHistory';
import { CallHistory } from '../../../api/callsHistory/types';
import { useCustomerIdFromOrganization } from '../../../hooks/customer/useCustomerIdFromOrganization';

interface UseCallDataArgs {
  callId?: string;
  callUuid?: string;
}

const useCallData = ({ callId, callUuid }: UseCallDataArgs) => {
  const { onlyCertainCustomer, customerId } = useCustomerIdFromOrganization();

  const request = useCallback(() => {
    if (onlyCertainCustomer && !customerId) {
      return Promise.resolve();
    }

    if (onlyCertainCustomer && callId) {
      return getCallDetailsByCustomerId(callId, customerId);
    }

    if (onlyCertainCustomer && callUuid) {
      return getCallDetailsByUuidAndCustomerId(callUuid, customerId);
    }

    if (callId) {
      return getCallDetails(callId);
    }

    if (callUuid) {
      return getCallDetailsByUuid(callUuid);
    }

    throw new Error('No call id provided');
  }, [callId, callUuid, customerId, onlyCertainCustomer]);

  const { loading, data, fetchData } = useDataFetch<CallHistory | null>({ request });

  return {
    loading,
    data,
    refetch: fetchData,
  };
};

export default useCallData;
