import { useCallback, useState } from 'react';
import { message } from 'antd';
import { AxiosError } from 'axios';

import mapCallParams from '../helpers/mappers';
import { OutboundCallState } from '../types';
import { endCallRequest, handCallOverRequest, startCallRequest } from '../../../api/outboundCall';
import { StartCallRequestResponse } from '../../../api/outboundCall/types';
import { CampaignOperators } from '../../../api/common/types';

interface UseOutboundCallLogicArgs {
  state: OutboundCallState;
  campaignOperator?: CampaignOperators;
}

const useOutboundCallLogic = ({ state, campaignOperator }: UseOutboundCallLogicArgs) => {
  const [loading, setLoading] = useState(false);
  const [callInProgress, setCallInProgress] = useState(false);
  const [callHandedOver, setCallHandedOver] = useState(false);
  const [callResponse, setCallResponse] = useState<StartCallRequestResponse | null>(null);

  const startCall = useCallback(async () => {
    setLoading(true);

    try {
      const response = await startCallRequest(mapCallParams({
        state,
        campaignOperator,
      }));

      setCallInProgress(true);
      setCallResponse(response.data);
    } catch (error) {
      if (error instanceof Error) {
        message.open({ content: error.message, type: 'error', duration: 10 });
      }
      if (error instanceof AxiosError) {
        message.open({ content: error.message, type: 'error', duration: 10 });
      }
      console.error(error);
    }

    setLoading(false);
  }, [campaignOperator, state]);

  const callTakeover = useCallback(async () => {
    if (!callResponse) {
      return;
    }

    setLoading(true);

    try {
      await handCallOverRequest(callResponse.twilio_conf_sid);
    } catch (error) {
      if (error instanceof AxiosError) {
        message.open({ content: error.message, type: 'error', duration: 10 });
      }
      console.error(error);
    }

    setCallHandedOver(true);
    setLoading(false);
  }, [callResponse]);

  const clearFormState = useCallback(() => {
    setLoading(false);
    setCallInProgress(false);
    setCallHandedOver(false);
    setCallResponse(null);
  }, []);

  const endCall = useCallback(async () => {
    if (!callResponse) {
      return;
    }

    setLoading(true);

    try {
      await endCallRequest({
        call_log_uuid: callResponse.call_log_uuid,
        twilio_conf_sid: callResponse.twilio_conf_sid,
        human_backup: state.humanBackup,
      });

      clearFormState();
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          clearFormState();
        } else {
          message.open({ content: error.message, type: 'error', duration: 10 });
        }
        console.error(error);
      }
    }

    setLoading(false);
  }, [callResponse, clearFormState, state.humanBackup]);

  return {
    callInProgress,
    callHandedOver,
    callResponse,
    loading,
    startCall,
    callTakeover,
    endCall,
    clearFormState,
  };
};

export default useOutboundCallLogic;
