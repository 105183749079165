import {
  Card,
  Tabs,
} from 'antd';

import Statements from './components/statement';
import SingleCall from './components/singleCall';
import CallsFromSpreadsheet from './components/callsFromSpreadsheet';
import useTabs from './hooks/useTabs';
import useOutboundCallState from './hooks/useOutboundCallState';

const OutboundCallForm = () => {
  const { tabs, activeTab, onTabChange } = useTabs();

  const {
    campaignOperatorsLoading,
    isStartCallDisabled,
    data,
    campaignsOptions,
    agentOptions,
    campaignOperator,
    onUpdate,
    onCampaignParamsUpdate,
  } = useOutboundCallState();

  return (
    <Card title={<Statements />} className="bg-white rounded shadow-lg">
      <Tabs
        className="mb-4"
        items={tabs}
        activeKey={activeTab}
        onChange={onTabChange}
      />
      {activeTab === tabs[0].key && (
        <SingleCall
          campaignOperatorsLoading={campaignOperatorsLoading}
          isStartCallDisabled={isStartCallDisabled}
          data={data}
          campaignsOptions={campaignsOptions}
          agentOptions={agentOptions}
          campaignOperator={campaignOperator}
          onUpdate={onUpdate}
          onCampaignParamsUpdate={onCampaignParamsUpdate}
        />
      )}
      {activeTab === tabs[1].key && (
        <CallsFromSpreadsheet
          campaignOperatorsLoading={campaignOperatorsLoading}
          data={data}
          campaignsOptions={campaignsOptions}
          agentOptions={agentOptions}
          campaignOperator={campaignOperator}
          onUpdate={onUpdate}
        />
      )}
    </Card>
  );
};

export default OutboundCallForm;
