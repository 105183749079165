import { DescriptionsItemType } from 'antd/es/descriptions';

import { idColumnKey } from '../../callsHistory/constants/columnsInitialState';

export interface Column extends DescriptionsItemType {
  availableForExternal: boolean;
}

export const initialCommonColumns: Column[] = [
  {
    key: idColumnKey,
    label: 'ID',
    children: '',
    availableForExternal: true,
  },
  {
    key: 'callLogUuid',
    label: 'Call uuid',
    children: '',
    availableForExternal: true,
  },
  {
    key: 'customerCallId',
    label: 'Customer call ID',
    children: '',
    availableForExternal: true,
  },
  {
    key: 'orchestratorNumberAssignment',
    label: 'Orchestrator number assignment ID',
    children: '',
    availableForExternal: false,
  },
  {
    key: 'callStatus',
    label: 'Call status',
    children: '',
    availableForExternal: true,
  },
  {
    key: 'callDirection',
    label: 'Direction',
    children: '',
    availableForExternal: true,
  },
  {
    key: 'startDate',
    label: 'Started',
    children: '',
    availableForExternal: true,
  },
  {
    key: 'endDate',
    label: 'Ended',
    children: '',
    availableForExternal: true,
  },
  {
    key: 'callDurationSec',
    label: 'Duration',
    children: '',
    availableForExternal: true,
  },
  {
    key: 'operatorNumber',
    label: 'Operator number',
    children: '',
    availableForExternal: false,
  },
  {
    key: 'agentNumber',
    label: 'Agent number',
    children: '',
    availableForExternal: true,
  },
  {
    key: 'humanBackupNumber',
    label: 'Human backup',
    children: '',
    availableForExternal: false,
  },
  {
    key: 'userNumber',
    label: 'User number',
    children: '',
    availableForExternal: true,
  },
  {
    key: 'user',
    label: 'User',
    children: '',
    availableForExternal: false,
  },
  {
    key: 'customer',
    label: 'Customer',
    children: '',
    availableForExternal: true,
  },
  {
    key: 'campaign',
    label: 'Campaign',
    children: '',
    availableForExternal: true,
  },
  {
    key: 'campaignJson',
    label: 'Campaign params',
    children: '',
    availableForExternal: false,
  },
  {
    key: 'orchestratorProvider',
    label: 'Orchestrator provider ID',
    children: '',
    availableForExternal: false,
  },
  {
    key: 'audioUrl',
    label: 'Audio URL',
    children: '',
    availableForExternal: true,
  },
  {
    key: 'transferAudioUrl',
    label: 'Transfer Audio URL',
    children: '',
    availableForExternal: true,
  },
  {
    key: 'humanLikeRating',
    label: 'Average human like rating',
    children: '',
    availableForExternal: false,
  },
  {
    key: 'callAction',
    label: 'Call actions',
    children: '',
    availableForExternal: false,
  },
];

export const initialTwilioColumns: Column[] = [
  {
    key: 'twilioConfSid',
    label: 'Twilio conf SID',
    children: '',
    availableForExternal: false,
  },
  {
    key: 'twilioAgentCallSid',
    label: 'Twilio agent call SID',
    children: '',
    availableForExternal: false,
  },
  {
    key: 'twilioHumanBackupCallSid',
    label: 'Twilio human backup call SID',
    children: '',
    availableForExternal: false,
  },
  {
    key: 'twilioContactCallSid',
    label: 'Twilio contact call SID',
    children: '',
    availableForExternal: false,
  },
];

export const initialRetellColumns: Column[] = [
  {
    key: 'retellCallId',
    label: 'Retell call ID',
    children: '',
    availableForExternal: false,
  },
  {
    key: 'retellAgentId',
    label: 'Retell agent ID',
    children: '',
    availableForExternal: false,
  },
  {
    key: 'audioProtocol',
    label: 'Audio protocol',
    children: '',
    availableForExternal: false,
  },
  {
    key: 'callCompletion',
    label: 'Call completion',
    children: '',
    availableForExternal: false,
  },
  {
    key: 'taskCompletion',
    label: 'Task completion',
    children: '',
    availableForExternal: false,
  },
  {
    key: 'userSentiment',
    label: 'User sentiment',
    children: '',
    availableForExternal: false,
  },
  {
    key: 'disconnectionReason',
    label: 'Disconnection reason',
    children: '',
    availableForExternal: false,
  },
  {
    key: 'averageEndToEndLatency',
    label: 'Average end to end latency',
    children: '',
    availableForExternal: false,
  },
  {
    key: 'summary',
    label: 'Summary',
    children: '',
    span: 2,
    availableForExternal: false,
  },
];

export const initialAuxRetellColumns: Column[] = [
  {
    key: 'auxRetellCallId',
    label: 'AUX Retell call ID',
    children: '',
    span: 2,
    availableForExternal: false,
  },
  {
    key: 'auxRetellCallAttributes',
    label: 'AUX Retell call attributes',
    children: '',
    span: 2,
    availableForExternal: false,
  },
];
