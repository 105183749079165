import {
  Flex,
  Typography,
} from 'antd';

import HowToUseAgentsParams from '../howToUseAgentsParams';
import { CampaignOperators } from '../../../../api/common/types';

interface HowToUseProps {
  campaignOperator?: CampaignOperators;
}

const HowToUse = ({ campaignOperator }: HowToUseProps) => (
  <Flex vertical>
    <Typography.Title className="mt-0" level={4}>How to use</Typography.Title>
    <ol className="list-decimal list-inside">
      <li>
        <Typography.Text>
          Choose campaign and agent alias
        </Typography.Text>
      </li>
      <li>
        <Typography.Text>
          Download and modify a sample file listed below
          or create a new .csv or .xlsx file and define all the required columns
        </Typography.Text>
      </li>
      <li>
        <Typography.Text>
          Click onto the section on the left and select the file
        </Typography.Text>
      </li>
      <li>
        <Typography.Text>
          Click &quot;Start calls&quot; button
        </Typography.Text>
      </li>
    </ol>
    <Typography.Title level={5}>Sample file</Typography.Title>
    <Typography.Link href="/TestSheet.xlsx">Download</Typography.Link>
    <Typography.Title level={5}>Required columns</Typography.Title>
    <ul className="list-disc list-inside">
      <li>
        <Typography.Text>
          <Typography.Text strong>twilio_number</Typography.Text>
          <Typography.Text>
            : Twilio phone number starting with &quot;+&quot;. E.g:
            {' '}
          </Typography.Text>
          <Typography.Text code>{campaignOperator?.originationNumber}</Typography.Text>
        </Typography.Text>
      </li>
      <li>
        <Typography.Text>
          <Typography.Text strong>agent_number</Typography.Text>
          <Typography.Text>
            : Agent phone number starting with &quot;+&quot;. E.g:
            {' '}
          </Typography.Text>
          <Typography.Text code>{campaignOperator?.orchestratorNumber}</Typography.Text>
        </Typography.Text>
      </li>
      <li>
        <Typography.Text>
          <Typography.Text strong>target_number</Typography.Text>
          <Typography.Text>
            : User phone number starting with &quot;+&quot;. E.g:
            {' '}
          </Typography.Text>
          <Typography.Text code>+11111111111</Typography.Text>
        </Typography.Text>
      </li>
      <li>
        <Typography.Text>
          <Typography.Text strong>human_backup_number</Typography.Text>
          <Typography.Text>
            : Human backup phone number starting with &quot;+&quot;. E.g:
            {' '}
          </Typography.Text>
          <Typography.Text code>+14154498283</Typography.Text>
        </Typography.Text>
      </li>
      {!!campaignOperator?.agentParams?.length && (
        <li>
          <Typography.Text>
            <Typography.Text strong>campaign_params</Typography.Text>
            <Typography.Text>
              : Depend on chosen campaign and agent. Each parameter should occupy its own column.
              Available params for
              {' '}
              <Typography.Text code>
                {campaignOperator?.orchestratorProvider.config.AgentName}
              </Typography.Text>
              :
              {' '}
            </Typography.Text>
          </Typography.Text>
          <HowToUseAgentsParams campaignOperator={campaignOperator} />
        </li>
      )}
    </ul>
  </Flex>
);

export default HowToUse;
