import { Descriptions, DescriptionsProps } from 'antd';
import { useMemo } from 'react';

import { StartCallRequestResponse } from '../../../../api/outboundCall/types';
import { CampaignOperators } from '../../../../api/common/types';
import { OutboundCallState } from '../../types';
import { addPlusToPhoneNumber } from '../../helpers';

import './index.scss';

interface CallResponseProps {
  callResponse?: StartCallRequestResponse | null;
  campaignOperator?: CampaignOperators;
  state: OutboundCallState;
}

const CallResponse = ({
  callResponse,
  campaignOperator,
  state,
}: CallResponseProps) => {
  const data = callResponse;

  const items: DescriptionsProps['items'] = useMemo(() => {
    let humanBackupPhone: string | null = 'Without human backup';

    if (state.humanBackup) {
      humanBackupPhone = data
        ? data?.human_backup_number
        : addPlusToPhoneNumber(state.humanBackupPhone);
    }

    return [
      {
        key: '0',
        label: 'Campaign name',
        children: campaignOperator?.orchestratorProvider.config.AgentName,
      },
      {
        key: '1',
        label: 'Twilio phone number',
        children: campaignOperator?.originationNumber,
      },
      {
        key: '2',
        label: 'Agent phone number',
        children: data?.agent_number ?? campaignOperator?.orchestratorNumber,
      },
      {
        key: '3',
        label: 'Human backup phone number',
        children: humanBackupPhone,
      },
      {
        key: '4',
        label: 'User phone number',
        children: data ? data?.user_number : addPlusToPhoneNumber(state.customerPhoneNumber),
      },
    ];
  }, [state, campaignOperator, data]);

  return (
    <Descriptions
      className="call-parameters-preview"
      column={1}
      title="Call details preview"
      items={items}
    />
  );
};

export default CallResponse;
