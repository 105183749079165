import { useCallback } from 'react';
import { Form, Switch } from 'antd';

import { OutboundCallState } from '../../types';

interface UserPhoneFieldProps {
  enableAmd: boolean;
  disabled: boolean;
  onUpdate: (fieldName: keyof OutboundCallState, value: string | boolean) => void;
}

const AmdSwitchField = ({
  enableAmd,
  onUpdate,
  disabled,
}: UserPhoneFieldProps) => {
  const onFieldStateChange = useCallback((checked: boolean) => {
    onUpdate('enableAmd', checked);
  }, [onUpdate]);

  return (
    <Form.Item label="Enable AMD">
      <Switch
        checked={enableAmd}
        onChange={onFieldStateChange}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default AmdSwitchField;
