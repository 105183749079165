import { Typography } from 'antd';
import { CampaignOperators } from '../../../../api/common/types';

interface HowToUseAgentsParamsProps {
  campaignOperator?: CampaignOperators;
}

const HowToUseAgentsParams = ({ campaignOperator }: HowToUseAgentsParamsProps) => {
  if (!campaignOperator) {
    return null;
  }

  return (
    <ul className="list-square ml-8">
      {campaignOperator.agentParams.map((agentParam) => {
        if (agentParam.type === 'select') {
          return (
            <li key={agentParam.key}>
              <Typography.Text strong>{agentParam.key}</Typography.Text>
              <Typography.Text>
                {` - ${agentParam.type}. Options: `}
              </Typography.Text>
              <Typography.Text code>{agentParam.options?.join(', ')}</Typography.Text>
            </li>
          );
        }

        return (
          <li key={agentParam.key}>
            <Typography.Text strong>{agentParam.key}</Typography.Text>
            <Typography.Text>
              {` - ${agentParam.type}. E.g: `}
            </Typography.Text>
            <Typography.Text code>{agentParam.placeholder}</Typography.Text>
          </li>
        );
      })}
    </ul>
  );
};

export default HowToUseAgentsParams;
