import { useParams } from 'react-router-dom';
import CallTranscript from '@marrlab-app-shared/components/callTranscript';
import CallAnnotations from '@marrlab-app-shared/components/callAnnotations';
import useCheckRole from '@marrlab-app-shared/hooks/auth';
import UserPermissions from '@marrlab-app-shared/constants/permissions';

import CoordinatorCallEvents from '../../features/coordinatorCallEvents';
import CallParams from '../../features/callParams';
import useCallData from './hooks/useCallData';
import { createCallAnnotation, editCallAnnotation, getPreSignedAudioUrl } from '../../api/callsHistory';

const CallDetails = () => {
  const { has } = useCheckRole();
  const canAccessInternalTools = has(UserPermissions.manageInternalTools);

  const { callId, callUuid } = useParams();

  const { data, loading, refetch: refetchCallData } = useCallData({ callId, callUuid });

  return (
    <>
      <CallParams loading={loading} data={data} />
      <CallTranscript
        loading={loading}
        data={data?.callTranscriptJson}
        audioUrl={data?.audioUrl ?? null}
        getPreSignedUrlRequest={getPreSignedAudioUrl}
      />
      {canAccessInternalTools && data && (
        <CallTranscript
          title="AUX transcript"
          loading={loading}
          data={data?.auxTranscriptJson}
          withAudio={false}
          audioUrl={null}
          getPreSignedUrlRequest={getPreSignedAudioUrl}
        />
      )}
      {canAccessInternalTools && data && (
        <CallAnnotations
          loading={loading}
          annotations={data?.annotations ?? []}
          callId={data?.callLogId}
          refetchCallData={refetchCallData}
          createCallAnnotation={createCallAnnotation}
          editCallAnnotation={editCallAnnotation}
        />
      )}
      {canAccessInternalTools && data && (
        <CoordinatorCallEvents
          callUuid={data?.callLogUuid}
        />
      )}
    </>
  );
};

export default CallDetails;
