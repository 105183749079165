import { useCallback } from 'react';
import { TableColumnsType } from 'antd';
import { useCheckRole } from '@marrlab-app-shared/hooks/auth';
import { UserPermissions } from '@marrlab-app-shared/constants/permissions';

import { mapColumnsSettingsToColumns } from '../utils/mappers';
import { columnsInitialState } from '../../../constants/columnsInitialState';
import { ColumnSetting } from '../types';
import { CallHistory } from '../../../../../api/callsHistory/types';
import { clearColumnsSettingsInStorage, saveColumnsSettingsToStorage } from '../../../utils/columnsSettingsPersistence';

interface UseColumnsModalLogicArgs {
  initialColumns: TableColumnsType<CallHistory>;
  columnsSettings: ColumnSetting[];
  onConfirm: (columns: TableColumnsType<CallHistory>) => void;
}

const useColumnsModalLogic = ({
  initialColumns,
  columnsSettings,
  onConfirm: onConfirmBase,
}: UseColumnsModalLogicArgs) => {
  const { has } = useCheckRole();

  const canAccessInternalTools = has(UserPermissions.manageInternalTools);

  const onConfirm = useCallback(() => {
    saveColumnsSettingsToStorage(columnsSettings);
    onConfirmBase(mapColumnsSettingsToColumns({ columnsSettings, columns: initialColumns }));
  }, [columnsSettings, initialColumns, onConfirmBase]);

  const onResetToDefault = useCallback(() => {
    clearColumnsSettingsInStorage();

    const filteredColumnsInitialState = columnsInitialState.filter((column) => {
      if (canAccessInternalTools) {
        return true;
      }

      return column.availableForExternal;
    });

    onConfirmBase(filteredColumnsInitialState);
  }, [canAccessInternalTools, onConfirmBase]);

  return {
    onConfirm,
    onResetToDefault,
  };
};

export default useColumnsModalLogic;
