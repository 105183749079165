import { CallAnnotation } from '@marrlab-app-shared/types';

export const getAverageRating = (annotations?: CallAnnotation[]) => {
  if (!annotations?.length) {
    return undefined;
  }

  const ratingSum = annotations.reduce((acc, annotation) => acc + annotation.humanLikeRating, 0);

  return ratingSum / annotations.length;
};

export const averageRatingRenderer = (annotations?: CallAnnotation[]) => {
  const rating = getAverageRating(annotations);

  return rating ? `${rating.toFixed(1)} / 5.0` : 'N/A';
};
