import { useCallback, useState } from 'react';
import { CallsHistoryFilters, CallsHistoryFiltersDates } from '../types';
import { getDefaultFilters } from '../utils/searchParams';

interface UseFiltersProps {
  resetPage: () => void;
}

const initialFiltersState: CallsHistoryFilters = {
  callDirection: undefined,
  campaign: undefined,
  callStatus: undefined,
  customer: undefined,
  user: undefined,
  callStartedFrom: undefined,
  callStartedTo: undefined,
  callEndedFrom: undefined,
  callEndedTo: undefined,
};

const useFilters = ({ resetPage }: UseFiltersProps) => {
  const [filters, setFilters] = useState<CallsHistoryFilters>(getDefaultFilters);

  const onFiltersUpdate = useCallback((
    filterName: keyof CallsHistoryFilters,
    value: CallsHistoryFilters[keyof CallsHistoryFilters],
    secondFilterName?: keyof CallsHistoryFiltersDates,
    secondValue?: CallsHistoryFilters[keyof CallsHistoryFiltersDates],
  ) => {
    resetPage();

    setFilters((state) => {
      const newState = {
        ...state,
        [filterName]: value,
      };

      if (secondFilterName) {
        newState[secondFilterName] = secondValue;
      }

      return newState;
    });
  }, [resetPage]);

  const clearFilters = useCallback(() => {
    resetPage();
    setFilters(initialFiltersState);
  }, [resetPage]);

  return {
    filters,
    onFiltersUpdate,
    clearFilters,
  };
};

export default useFilters;
