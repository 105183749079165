import { List, Switch, Typography } from 'antd';

import './index.scss';

interface ColumnsSettingsHeaderProps {
  allVisible: boolean;
  onAllVisibleUpdate: () => void;
}

const ColumnsSettingsHeader = ({ allVisible, onAllVisibleUpdate }: ColumnsSettingsHeaderProps) => (
  <>
    <List.Item>
      <div className="drag-column-header" />
      <Typography.Text strong>Column</Typography.Text>
      <Typography.Text className="ml-auto" strong>Visibility</Typography.Text>
    </List.Item>
    <List.Item>
      <div className="drag-column-header" />
      <Typography.Text strong>All</Typography.Text>
      <Switch
        className="ml-auto"
        checked={allVisible}
        onChange={onAllVisibleUpdate}
      />
    </List.Item>
  </>
);

export default ColumnsSettingsHeader;
