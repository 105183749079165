import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { getCallbackLogByCallLogUuid } from '../../../api/callsHistory';
import { CallbackLog } from '../../../api/common/types';
import { formatNumber } from '../../../utils/numbers';

interface UseCallbackLogDataArgs {
  callLogUuid?: string;
}

const resolveCallSummaryValue = (value?: unknown) => {
  if (typeof value === 'number') {
    return formatNumber(value);
  }

  return value?.toString?.();
};

const calculateCallSummaryWeight = (callbackLog: CallbackLog) => (
  Object.values(callbackLog.callbackObject.call_summary).reduce((acc: number, value) => {
    if (value === null || value === undefined) {
      return acc;
    }

    if (typeof value === 'number' || typeof value === 'boolean') {
      return acc + 1;
    }

    if (typeof value === 'string') {
      return value.length ? acc + 1 : acc;
    }

    if (Array.isArray(value)) {
      return (value as unknown[]).length ? acc + 1 : acc;
    }

    return acc + 1;
  }, 0)
);

const sortCallbackLogs = (callbackLogs: CallbackLog[]) => {
  const recordsWithCallSummary = callbackLogs?.filter((item) => (
    item?.callbackObject?.call_summary
  )) ?? [];

  if (!recordsWithCallSummary.length) {
    return [];
  }

  recordsWithCallSummary.sort((a, b) => {
    const aWeight = calculateCallSummaryWeight(a);
    const bWeight = calculateCallSummaryWeight(b);

    return bWeight - aWeight;
  });

  return recordsWithCallSummary;
};

const useCallbackLogData = ({
  callLogUuid,
}: UseCallbackLogDataArgs) => {
  const [data, setData] = useState<CallbackLog[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedCallbackLogIndex, setSelectedCallbackLogIndex] = useState(0);

  const fetchCallbackLog = useCallback(async () => {
    if (!callLogUuid) {
      return;
    }

    setLoading(true);
    try {
      const response = await getCallbackLogByCallLogUuid(callLogUuid);

      setData(sortCallbackLogs(response.data));
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  }, [callLogUuid]);

  const onSelect = useCallback((value: number) => {
    setSelectedCallbackLogIndex(value);
  }, []);

  const callSummaryColumns = useMemo(() => {
    const callSummary = data[selectedCallbackLogIndex]?.callbackObject?.call_summary;

    if (!callSummary) {
      return [];
    }

    return Object.entries(callSummary).map(([key, value]) => ({
      key,
      label: key.replaceAll('_', ' '),
      className: 'label-capitalize',
      children: resolveCallSummaryValue(value),
    }));
  }, [data, selectedCallbackLogIndex]);

  useEffect(() => {
    fetchCallbackLog();
  }, [fetchCallbackLog]);

  return {
    data,
    callSummaryColumns,
    loading,
    selectedCallbackLogIndex,
    onSelect,
  };
};

export default useCallbackLogData;
