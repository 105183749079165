import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { OutboundCallState } from '../types';
import useCampaignOperators from './useCampaignOperators';
import { findCampaignOperator } from '../helpers';

const initialState: OutboundCallState = {
  agentAliasId: undefined,
  campaign: '',
  customerPhoneNumber: '',
  humanBackup: false,
  humanBackupPhone: '',
  campaignParams: {},
  enableAmd: true,
};

const useOutboundCallState = () => {
  const {
    loading: campaignOperatorsLoading,
    campaignsOptions,
    agentOptionsMap,
    campaignOperators,
  } = useCampaignOperators();

  const [data, setData] = useState<OutboundCallState>(initialState);

  const onUpdate = useCallback((
    fieldName: keyof OutboundCallState,
    value: string | boolean | number,
  ) => {
    setData((state) => {
      const isChangingCampaign = fieldName === 'campaign' && state.campaign !== value;

      return {
        ...state,
        agentAliasId: isChangingCampaign
          ? agentOptionsMap[value as string]?.[0]?.key ?? undefined
          : state.agentAliasId,
        [fieldName]: value,
        campaignParams: isChangingCampaign ? {} : state.campaignParams,
      };
    });
  }, [agentOptionsMap]);

  const onCampaignParamsUpdate = useCallback((
    fieldName: string,
    value: string,
  ) => {
    setData((state) => ({
      ...state,
      campaignParams: {
        ...state.campaignParams,
        [fieldName]: value,
      },
    }));
  }, []);

  useEffect(() => {
    if (campaignOperators.length) {
      setData((state) => ({
        ...state,
        campaign: campaignOperators[0].orchestratorProvider.campaign?.displayName ?? '',
        agentAliasId: campaignOperators[0].orchestratorNumberAssignmentId,
      }));
    }
  }, [campaignOperators]);

  const campaignOperator = useMemo(() => findCampaignOperator({
    campaignOperators,
    agentAliasId: data.agentAliasId,
  }), [campaignOperators, data.agentAliasId]);

  const isStartCallDisabled = campaignOperatorsLoading
    || !data.campaign
    || !data.agentAliasId
    || !data.customerPhoneNumber
    || (data.humanBackup ? !data.humanBackupPhone : false);

  return {
    campaignOperatorsLoading,
    isStartCallDisabled,
    data,
    campaignsOptions,
    agentOptions: agentOptionsMap[data.campaign] ?? [],
    campaignOperator,
    onUpdate,
    onCampaignParamsUpdate,
  };
};

export default useOutboundCallState;
