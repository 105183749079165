import dayjs from 'dayjs';

import { CoordinatorCallEvent } from '../../../api/coordinatorCallEvents/types';
import { CoordinatorCallEventTableRow } from '../types';

export const calculateDurationFromCreatedEvent = (
  events: CoordinatorCallEvent[],
): CoordinatorCallEventTableRow[] => {
  const createdEvent = events.find((event) => event.eventType === 'CREATED');

  if (!createdEvent) {
    return events;
  }

  const createdTime = dayjs(createdEvent.timestamp);

  return events.map((event) => {
    const currentTime = dayjs(event.timestamp);

    return {
      ...event,
      callTime: event.eventType === 'CREATED'
        ? undefined
        : dayjs.duration(currentTime.diff(createdTime)).asSeconds(),
    };
  });
};

export default calculateDurationFromCreatedEvent;
