import axios from 'axios';

import {
  CoordinatorCallEvent,
  GetCoordinatorCallEventsParams,
} from './types';
import config from '../../utils/config';

export const getCoordinatorCallEvents = async (
  callUuid: string,
  params: GetCoordinatorCallEventsParams,
) => (
  axios.get<CoordinatorCallEvent[]>(`${config.CALLS_HISTORY_API_BASE_URL}/api/coordinator-call-events/${callUuid}`, { params })
);

export default getCoordinatorCallEvents;
