import { CampaignOperators } from '../../../api/common/types';

interface FindCampaignOperatorArgs {
  campaignOperators: CampaignOperators[];
  agentAliasId?: number;
}

export const findCampaignOperator = ({
  campaignOperators,
  agentAliasId,
}: FindCampaignOperatorArgs) => (
  campaignOperators.find((config) => (
    config.orchestratorNumberAssignmentId === agentAliasId
  ))
);

export const addPlusToPhoneNumber = (number: string) => {
  if (!number) {
    return '';
  }

  if (number.startsWith('+')) {
    return number;
  }

  return `+${number}`;
};

export default findCampaignOperator;
