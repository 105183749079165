import { useCallback, useState, DragEvent } from 'react';
import { message, UploadFile } from 'antd';

import { FileType } from '../types';
import { allowedFileTypes } from '../constants';

const useCallsFromSpreadsheetState = () => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const onRemove = useCallback(() => {
    setFileList([]);
  }, []);

  const beforeUpload = useCallback((file: FileType) => {
    setFileList([file]);

    return false;
  }, []);

  const onDrop = useCallback((event: DragEvent) => {
    const files = [...event.dataTransfer.files];

    if (!files.length) {
      return;
    }

    if (files.length > 1) {
      message.error('Only one file is allowed');
      return;
    }

    if (!allowedFileTypes.includes(files[0].type)) {
      message.error(`File type of ${files[0].name} is not supported`);
      return;
    }

    setFileList([...event.dataTransfer.files] as unknown as UploadFile[]);
  }, []);

  return {
    fileList,
    onRemove,
    beforeUpload,
    setFileList,
    onDrop,
  };
};

export default useCallsFromSpreadsheetState;
