import { useCallback, useState } from 'react';
import { TabsProps } from 'antd';

const tabs: TabsProps['items'] = [
  {
    key: 'singleCall',
    label: 'Single call',
  },
  {
    key: 'callsFromSpreadsheet',
    label: 'Calls from spreadsheet',
  },
];
const useTabs = () => {
  const [activeTab, setActiveTab] = useState<string>(tabs[0].key);

  const onTabChange = useCallback((tabKey: string) => {
    setActiveTab(tabKey);
  }, []);

  return {
    tabs,
    activeTab,
    onTabChange,
  };
};

export default useTabs;
