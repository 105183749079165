import {
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import { message, UploadFile } from 'antd';
import { AxiosError } from 'axios';

import { FileType } from '../types';
import { uploadCallsSpreadsheet } from '../../../api/outboundCall';
import { CampaignOperators } from '../../../api/common/types';

interface UseCallsFromSpreadsheetLogicArgs {
  fileList: UploadFile[];
  setFileList: Dispatch<SetStateAction<UploadFile[]>>;
  campaignOperator?: CampaignOperators;
}

const useCallsFromSpreadsheetLogic = ({
  fileList,
  setFileList,
  campaignOperator,
}: UseCallsFromSpreadsheetLogicArgs) => {
  const [uploading, setUploading] = useState(false);

  const onUpload = useCallback(async () => {
    setUploading(true);

    try {
      if (!campaignOperator?.orchestratorProvider.orchestratorProviderId) {
        message.open({
          content: 'Campaign and Agent alias are mandatory',
          type: 'error',
          duration: 10,
        });

        setUploading(false);
        return;
      }

      const response = await uploadCallsSpreadsheet({
        file: fileList[0] as unknown as FileType,
        orchestratorProviderId: campaignOperator?.orchestratorProvider.orchestratorProviderId,
        callParams: campaignOperator.agentParams.map((param) => param.key),
      });

      setFileList([]);

      message.success(response.data?.message ?? 'File uploaded successfully');
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        message.open({
          content: error.response?.data?.detail
            ? JSON.stringify(error.response?.data?.detail)
            : error.message,
          type: 'error',
          duration: 10,
        });
      }
    }

    setUploading(false);
  }, [campaignOperator, fileList, setFileList]);

  return {
    uploading,
    onUpload,
  };
};

export default useCallsFromSpreadsheetLogic;
