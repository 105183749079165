import {
  Card,
  Table,
  Typography,
} from 'antd';

import useCoordinatorCallEventsData from './hooks/useCoordinatorCallEventsData';
import useSorting from './hooks/useSorting';
import useTableConfiguration from './hooks/useTableConfiguration';
import { idColumnKey } from './constants/columnsInitialState';

import './index.scss';

interface CoordinatorCallEventsProps {
  callUuid: string;
}

const CoordinatorCallEvents = ({
  callUuid,
}: CoordinatorCallEventsProps) => {
  const {
    columns,
    sticky,
    scroll,
    setColumns,
  } = useTableConfiguration();
  const { sortBy, sortDirection, onSortChange } = useSorting({ setColumns });
  const { data, loading } = useCoordinatorCallEventsData(callUuid, {
    sortBy,
    order: sortDirection,
  });

  return (
    <Card
      title={(
        <Typography.Title
          level={4}
          className="coordinator-call-events-title"
        >
          Coordinator call events
        </Typography.Title>
      )}
      className="bg-white rounded shadow-lg mt-6"
    >
      <div className="table-wrapper">
        <Table
          rowKey={idColumnKey}
          dataSource={data ?? []}
          columns={columns}
          loading={loading}
          scroll={scroll}
          pagination={false}
          sticky={sticky}
          onChange={onSortChange}
        />
      </div>
    </Card>
  );
};

export default CoordinatorCallEvents;
