import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import { wrapCreateBrowserRouter } from '@sentry/react';
import ClerkProvider from '@marrlab-app-shared/components/clerkProvider';

import MainPage from './pages/main';
import NotFoundPage from './pages/notFound';
import SignInPage from './pages/signIn';
import CallDetails from './pages/callDetails';
import OutboundCall from './pages/outboundCall';
import PageLayout from './components/pageLayout';
import Navigation from './components/navigation';
import UnauthorizedPageLayout from './components/unauthorizedPageLayout';
import config from './utils/config';

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

const redirectOrigins = config.MODE === 'development'
  ? [
    'https://vox-configuration-dev.marrlabs.io',
    'https://call-operator-dev.marrlabs.io',
    'https://review-app-dev.marrlabs.io',
  ] : [
    'https://call-operator.marrlabs.io',
    'https://vox-configuration.marrlabs.io',
    'https://review-app.marrlabs.io',
  ];

const router = sentryCreateBrowserRouter([
  {
    element: <ClerkProvider
      publishableKey={config.CLERK_PUBLISHABLE_KEY}
      allowedRedirectOrigins={redirectOrigins}
    />,
    children: [
      {
        element: <PageLayout />,
        children: [
          {
            element: <Navigation />,
            children: [
              {
                index: true,
                element: <MainPage />,
              },
              {
                path: 'call-operator',
                element: <OutboundCall />,
              },
            ],
          },
          {
            path: 'call-details/:callId',
            element: <CallDetails />,
          },
          {
            path: 'call-details/uuid/:callUuid',
            element: <CallDetails />,
          },
          {
            path: '*',
            element: <NotFoundPage />,
          },
        ],
      },
      {
        path: 'sign-in',
        element: <UnauthorizedPageLayout />,
        children: [
          {
            index: true,
            element: <SignInPage />,
          },
          {
            path: 'factor-one',
            element: <SignInPage />,
          },
        ],
      },
    ],
  },
]);

const Router = () => (
  <RouterProvider router={router} />
);

export default Router;
