import { OutboundCallState } from '../types';
import { StartCallRequestBody } from '../../../api/outboundCall/types';
import { CampaignOperators } from '../../../api/common/types';
import { addPlusToPhoneNumber } from './index';

const validateMappedCallParams = ({
  agent_number,
  orchestrator_number_assignment_id,
  human_backup,
  human_backup_number,
  user_number,
}: StartCallRequestBody) => {
  if (!orchestrator_number_assignment_id || !agent_number) {
    throw new Error('RetellAI phone number should not be empty');
  }

  if (!user_number) {
    throw new Error('User phone number should not be empty');
  }

  if (human_backup && !human_backup_number) {
    throw new Error('Backup phone number should not be empty');
  }

  if (human_backup_number === user_number) {
    throw new Error(`Human backup number: ${human_backup_number} and user number: ${user_number} can not be the same`);
  }
};

interface MapCallParamsArgs {
  state: OutboundCallState;
  campaignOperator?: CampaignOperators;
}

const mapCallParams = ({
  state: {
    campaign,
    customerPhoneNumber,
    campaignParams,
    humanBackup,
    humanBackupPhone,
    enableAmd,
  },
  campaignOperator,
}: MapCallParamsArgs) => {
  if (!campaignOperator) {
    throw new Error(`Not handled campaign client name: ${campaign}`);
  }

  const mappedState: StartCallRequestBody = {
    enable_amd: enableAmd,
    agent_number: campaignOperator.orchestratorNumber,
    orchestrator_number_assignment_id: campaignOperator.orchestratorNumberAssignmentId,
    user_number: addPlusToPhoneNumber(customerPhoneNumber),
    human_backup: humanBackup,
    human_backup_number: humanBackup ? addPlusToPhoneNumber(humanBackupPhone) : undefined,
    prompt_params: campaignParams,
  };

  validateMappedCallParams(mappedState);

  return mappedState;
};

export default mapCallParams;
