export enum CallStatusEnum {
  Completed = 'completed',
  InProgress = 'in_progress',
  NoAnswerDash = 'no-answer',
  NoAnswer = 'no_answer',
  Busy = 'busy',
}

export const CallStatusNames = {
  [CallStatusEnum.Completed]: 'Completed',
  [CallStatusEnum.InProgress]: 'In progress',
  [CallStatusEnum.Busy]: 'Busy',
  [CallStatusEnum.NoAnswer]: 'No answer',
  [CallStatusEnum.NoAnswerDash]: 'No answer',
};

export enum SortOrder {
  ascend = 'ASC',
  descend = 'DESC',
}
