import { useCallback } from 'react';
import { Menu } from 'antd';
import { HistoryOutlined, PhoneOutlined } from '@ant-design/icons';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Protect } from '@clerk/clerk-react';
import { UserPermissions } from '@marrlab-app-shared/constants/permissions';
import { useCheckRole } from '@marrlab-app-shared/hooks/auth';

import './index.scss';

const menuItems = [
  {
    label: 'Calls history',
    key: '/',
    icon: <HistoryOutlined />,
  },
  {
    label: 'Call operator',
    key: '/call-operator',
    icon: <PhoneOutlined />,
  },
];

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { has } = useCheckRole();

  const onMenuClick = useCallback((info: { key: string }) => {
    navigate(info.key);
  }, [navigate]);

  return (
    <>
      <Protect condition={() => has(UserPermissions.manageInternalTools)}>
        <Menu
          className="global-navigation-menu mb-6"
          onClick={onMenuClick}
          selectedKeys={[location.pathname]}
          mode="horizontal"
          items={menuItems}
        />
      </Protect>
      <Outlet />
    </>
  );
};

export default Navigation;
