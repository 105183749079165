import { Collapse } from 'antd';

import AgentParamsFields from '../agentParamsFields';
import { CampaignParams as CampaignParamsType } from '../../types';
import { CampaignOperators } from '../../../../api/common/types';

interface AgentParamsProps {
  data: CampaignParamsType;
  agentParams: CampaignOperators['agentParams'];
  disabled: boolean;
  onUpdate: (propName: keyof CampaignParamsType, value: string) => void;
}

const AgentParams = ({
  data,
  agentParams,
  onUpdate,
  disabled,
}: AgentParamsProps) => {
  if (!agentParams?.length) {
    return null;
  }

  return (
    <Collapse
      className="mb-6"
      collapsible="header"
      size="small"
      items={[
        {
          key: '1',
          label: 'Setup campaign params',
          children: (
            <AgentParamsFields
              agentParams={agentParams}
              data={data}
              disabled={disabled}
              onUpdate={onUpdate}
            />
          ),
        },
      ]}
    />
  );
};

export default AgentParams;
