import { TableColumnsType } from 'antd';
import { SortOrder as AntSortOrder } from 'antd/es/table/interface';

import { CallHistory } from '../../../../../api/callsHistory/types';
import { ColumnSetting } from '../types';
import { getDefaultSorting } from '../../../utils/searchParams';
import { SortOrder } from '../../../../../constants';

export const mapColumnsToColumnsSettings = (
  columns: TableColumnsType<CallHistory>,
): ColumnSetting[] => (
  columns.map((item) => ({
    key: item.key as string,
    title: item.title as string,
    visible: !item.hidden,
  }))
);

interface MapColumnsSettingsToColumnsArgs {
  columns: TableColumnsType<CallHistory>;
  columnsSettings?: ColumnSetting[];
}

export const mapColumnsSettingsToColumns = ({
  columnsSettings,
  columns,
}: MapColumnsSettingsToColumnsArgs): TableColumnsType<CallHistory> => {
  const defaultSorting = getDefaultSorting();

  const columnsWithSorting = columns.map((column) => {
    if (column.key === defaultSorting.sortBy) {
      return {
        ...column,
        sortOrder: (
          defaultSorting.order === SortOrder.ascend
            ? 'ascend'
            : 'descend'
        ) as AntSortOrder,
      };
    }

    return column;
  });

  if (!columnsSettings) {
    return columnsWithSorting;
  }

  const columnsSettingsMap = columnsSettings.reduce((acc, setting, index) => {
    acc[setting.key] = {
      ...setting,
      index,
    };

    return acc;
  }, {} as Record<string, ColumnSetting & { index: number }>);

  const mappedColumns = columnsWithSorting.map((column) => {
    const settings = columnsSettingsMap[column.key as string];

    return {
      ...column,
      hidden: !(settings?.visible ?? true),
    };
  });

  mappedColumns.sort((a, b) => (
    (columnsSettingsMap[a.key as string]?.index ?? 0)
    - (columnsSettingsMap[b.key as string]?.index ?? 0)
  ));

  return mappedColumns;
};
